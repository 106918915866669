import {CHALLAN_ALL_EXPORT} from "../../constant/ActionType";

export default function challanExportReducer(state = {
    challanexportsss:[],
    statusCode: null,
}, action) {
    switch (action.type) {    
        case CHALLAN_ALL_EXPORT:
			return { ...state,
				challanexportsss: action.data ,
                statusCode:action.status
            };

        default:
    }
    return state;
}
