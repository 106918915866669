// type
import { Home3, Tag2, Colorfilter, Barcode } from 'iconsax-react';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { AES, enc } from 'crypto-js';
import * as url from "../store/constant/Endpoints";

// icons
const icons = {
  navigation: Home3,
  typography: Tag2,
  color: Colorfilter,
  shadow: Barcode,
  note:EditNoteIcon,
};

// let permissionUser = localStorage.getItem("LoginData");
// let permissions = JSON.parse(permissionUser)?.permissions;

// const allowedPermissions = permissions && permissions.length !== 0 ? permissions.map(permission => permission?.permission) : [];

// const permissionsAccessFunction = (batch) => {
//   return allowedPermissions && allowedPermissions.length !== 0 && allowedPermissions.some(permission => permission.includes(batch));
// }

let permissionUser = localStorage.getItem('logindata');
let decryptedData = null;
if (permissionUser) {
  try {
    decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(enc.Utf8);

  } catch (error) {
    console.log(error)
  }
}




const permissions = decryptedData ? JSON.parse(decryptedData) : null;
const allowedPermissions = permissions && permissions.length !== 0 ? permissions.map((permission) => permission?.permission) : [];
const permissionsAccessFunction = (brand) => {

  return allowedPermissions && allowedPermissions.length !== 0 && allowedPermissions.some((permission) => permission.includes(brand));
};


// ==============================|| MENU ITEMS - batch ||============================== //

const deliveryNote =
  permissionsAccessFunction('challan') ?
    {
      id: 'utilities',
      // title: 'Delivery Note',
      icon: icons.Tag2,
      type: 'group',
      children: [

        allowedPermissions.includes('challan.get') ?
          {
            id: 'all-challan',
            title: 'Delivery Note',
            type: 'item',
            url: '/delivery-note/all-challan',
            icon: icons.note,
            breadcrumbs: true
          }
          : null
        ,



      ].filter(Boolean)
    }
    : null;

export default deliveryNote;

