import {UPDATE_CUSTOMER} from "../../constant/ActionType";

export default function editCustomerReducer(state = {
    editCustomer:[],
    statusCode: null,
}, action) {
    switch (action.type) {    
        case UPDATE_CUSTOMER:
			return { ...state,
				editCustomer: action.data ,
                statusCode:action.status
            };

        default:
    }
    return state;
}
