import {
    NEWTEMAE_DETAILS
} from "../../constant/ActionType";

export default function TemplateDestailssReducer(state = {
    templatedetail: [],
    statusCode: null,
}, action) {
    switch (action.type) {
        case NEWTEMAE_DETAILS:
            return {
                ...state,
                templatedetail: action.data,
                    statusCode: action.status
            };

        default:
    }
    return state;
}