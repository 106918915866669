import React, { useState, useRef } from 'react';
import { Space, Input, Select } from 'antd';
import { IconButton } from '@mui/material';
import { CloseSquare, SearchNormal1 } from 'iconsax-react';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

const { Option } = Select;

const GetColumnSearchProps = (dataIndex, params, setParams, statusValues) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    setParams({ ...params, [dataIndex]: selectedKeys[0] ?? '', page: 1 });
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, close, dataIndex) => {
    clearFilters();
    setSearchText('');
    setSearchedColumn('');
    setParams({ ...params, [dataIndex]: '', page: 1 });
    close();
  };

  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => {
      return (
        <div style={{ padding: 10 }}>
          {dataIndex === 'status' ? (
            <Select
              ref={searchInput}
              placeholder={`Select Status`}
              value={selectedKeys[0]}
              onChange={(value) => setSelectedKeys(value ? [value] : [])}
              style={{ display: 'block', width: '100%' }}
            >
              {statusValues?.map((status) => (
                <Option key={status} value={status}>
                  {status == 1 ? 'Active' : 'Inactive'}
                </Option>
              ))}
            </Select>
          ) : (
            <Input
              ref={searchInput}
              placeholder={`Search`}
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ display: 'block' }}
            />
          )}
          <Space className="searchButtons">
            <IconButton
              className="click"
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              variant="outlined"
              size="small"
              style={{ width: 30, height: 30 }}
            >
              <SearchNormal1 variant="Bulk" color="#e75055" />
            </IconButton>
            {/* <IconButton
              className="click"
              onClick={() => clearFilters && handleReset(clearFilters, close, dataIndex)}
              size="small"
              variant="outlined"
              style={{ width: 30, height: 30, backgroundColor: "#DBE0E5" }}
            >
              <Refresh variant="Bulk" color="#0B3D58" />
            </IconButton> */}
            <IconButton
              className="click"
              type="link"
              size="small"
              style={{ width: 30, height: 30, backgroundColor: '#DBE0E5' }}
              variant="outlined"
              onClick={() => clearFilters && handleReset(clearFilters, close, dataIndex)}
            >
              <CloseSquare variant="Bulk" color="#a61611" />
            </IconButton>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.focus(), 100);
      }
    },
    render: (text) => {
      return searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape={true}
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      );
    }
  };
};

export default GetColumnSearchProps;


export const SearchQueryFormatter=(data)=>{
  let result = '';

for (let key in data) {
    if (data[key] !== '' && data[key] !== undefined && data[key] !== null) {
        result += `&${key}=${data[key]}`;
    }
}

return result;
}