import { lazy } from "react";

// project-imports
import MainLayout from "layout/MainLayout";
import Loadable from "components/Loadable";
import AllChartOfAccount from "pages/ChartOfAccount/allChartOfAccount";
import CreateChartOfAccount from "pages/ChartOfAccount/cuChartOfAccount";
import CreatePaymentVoucher from "pages/paymentVoucher/CuPaymentVoucher";
import * as url from "../store/constant/Endpoints";

import CreateTermsAndCondition from "pages/TermsAndConditions/cuTermsAndCondition";
import AllTermsAndCondition from "pages/TermsAndConditions/allTermsAndCondition";
import CreateTemplate from "pages/Templates/cuTemplate";
import AllTemplates from "pages/Templates/allTemplates";
import CreateQuotation from "pages/Quotation/createquotation";
import ViewSingleQuotation from "pages/Quotation/ViewSingleQuotation";
import CreateChallan from "pages/Challan/CreateChallan";
// import ChallanDetails from "pages/Challan/challandetail";
import { AES, enc } from "crypto-js";

const DashboardDefault = Loadable(lazy(() => import("pages/dashboard")));
// render - data display components
const PermissionsAssign = Loadable(
  lazy(() => import("pages/permissions/assign"))
);

const CompanyAll = Loadable(lazy(() => import("pages/company/allcompany")));
const AllInvoice = Loadable(lazy(() => import("pages/invoice/allinvoice")));
const CreateInvoice = Loadable(
  lazy(() => import("pages/invoice/createInvoice"))
);
const PaymntDetails = Loadable(lazy(() => import("pages/paymentVoucher/DetailPaymentVchr")));

const InvoiceDetails = Loadable(
  lazy(() => import("pages/invoice/invoicedetail"))
);
const AllRoles = Loadable(lazy(() => import("pages/user-management/allroles")));
const AllUsers = Loadable(lazy(() => import("pages/user-management/allusers")));
const AllUsersRegister = Loadable(lazy(() => import("pages/user-management/registerUser")));

const AllQuotations = Loadable(
  lazy(() => import("pages/Quotation/Viewquotation"))
);
const PaymentCoucherView = Loadable(
  lazy(() => import("pages/paymentVoucher/paymentvoucher"))
);
// const CreatePaymentVoucher = Loadable(lazy(() => import('pages/paymentVoucher/CuPaymentVoucher')));

const CreateCompany = Loadable(
  lazy(() => import("pages/company/createeditcompany"))
);

const ExpenseVoucher = Loadable(
  lazy(() => import("pages/paymentVoucher/ExpenseVoucher"))
);




const CreateCustomer = Loadable(
  lazy(() => import("../../src/pages/company/Customer.js"))
);

const CustomerCreate = Loadable(lazy(() => import("../../src/pages/company/CreateCustomer.js")));


const AllTaxes = Loadable(lazy(() => import("pages/Taxes/allTaxes")));
const CreateTaxes = Loadable(lazy(() => import("pages/Taxes/cuTaxes")));
// const Modals = Loadable(lazy(() => import("../companymodal")));



const AllChallan = Loadable(lazy(() => import("pages/Challan/Challan")));
const ChallanDetails = Loadable(lazy(() => import("pages/Challan/challandetail")));
const AllRecuringEmail = Loadable(lazy(() => import("pages/recuringcrud/allrecuringemail")));
const CreaterecuringEmail = Loadable(lazy(() => import("pages/recuringcrud/curecuringemail")));

const AllReports = Loadable(lazy(() => import("pages/report/Allreports")));
const ReportsbyClients = Loadable(lazy(() => import("pages/report/ReportsbyClients")));
const ReportsbyCompany = Loadable(lazy(() => import("pages/report/ReportsbyCompany")));
const Ledger = Loadable(lazy(() => import("pages/report/Ledger.js")));
const PdfPage = Loadable(lazy(() => import("pages/pdf/pdfpage")));




// render - utils components page
// const Color = Loadable(lazy(() => import('pages/components-overview/color')));
// const Shadow = Loadable(lazy(() => import('pages/components-overview/shadows')));

// pages routing

// ==============================|| MAIN ROUTES ||============================== //
let permissionUser = localStorage.getItem("logindata");
let decryptedData = null;
if (permissionUser) {
  decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(
    enc.Utf8
  );
}

const permissions = JSON.parse(decryptedData);
const allowedPermissions =
  permissions && permissions.length !== 0
    ? permissions.map((permission) => permission?.permission)
    : [];
console.log("yes2", allowedPermissions);
const permissionsAccessFunction = (brand) => {
  return (
    allowedPermissions &&
    allowedPermissions.length !== 0 &&
    allowedPermissions.some((permission) => permission.includes(brand))
  );
};

const MainRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: <MainLayout />,
      children: [
        {
          path: "/",
          element: <DashboardDefault />,
        },
        {
          path: "dashboard",
          element: <DashboardDefault />,
        },
        {
          path: "*",
          element: <DashboardDefault />,
        },
        {
          path: "pdf",
          element: <PdfPage />,
        },
      ],
    },
    permissionsAccessFunction("company")
      ? {
          path: "domain-parameter",
          element: <MainLayout />,
          children: [
            allowedPermissions.includes("company.getAll")
              ? {
                  path: "all-company",
                  element: <CompanyAll />,
                }
              : null,
              allowedPermissions.includes("company.getAll")
              ? {
                  path: "all-customer",
                  element: <CreateCustomer/>,
                }
              : null,
              allowedPermissions.includes("company.getAll")
              ? {
                  path: "create-customer",
                  element: <CustomerCreate/>,
                }
              : null,
              
            allowedPermissions.includes("tax.getAll")
              ? {
                  path: "all-taxes",
                  element: <AllTaxes />,
                }
              : null,
            allowedPermissions.includes("account.getAll")
              ? {
                  path: "chart-of-account",
                  element: <AllChartOfAccount />,
                }
              : null,
            allowedPermissions.includes("terms.register")
              ? {
                  path: "terms-and-conditions",
                  element: <AllTermsAndCondition />,
                }
              : null,
            allowedPermissions.includes("templetes.get.all")
              ? {
                  path: "all-templates",
                  element: <AllTemplates />,
                }
              : null,
            allowedPermissions.includes("payment.getAll")
              ? {
                  path: "payment-voucher",
                  element: <PaymentCoucherView />,
                }
              : null,
              allowedPermissions.includes("payment.getAll")
              ? {
                  path: "expense-voucher",
                  element: <ExpenseVoucher />,
                }
              : null,
            // : null,
            // allowedPermissions.includes('register-company') || allowedPermissions.includes('edit-company')

            allowedPermissions.includes("company_register")
              ? {
                  path: "create-company",
                  element: <CreateCompany />,
                }
              : null,

            allowedPermissions.includes("tax.register")
              ? {
                  path: "create-tax",
                  element: <CreateTaxes />,
                }
              : null,
            allowedPermissions.includes("account.register")
              ? {
                  path: "create-account",
                  element: <CreateChartOfAccount />,
                }
              : null,
            allowedPermissions.includes("terms.register")
              ? {
                  path: "create-terms-and-condition",
                  element: <CreateTermsAndCondition />,
                }
              : null,
            allowedPermissions.includes("templetes.store")
              ? {
                  path: "create-template",
                  element: <CreateTemplate />,
                }
              : null,
            allowedPermissions.includes("payment.store")
              ? {
                  path: "create-payment-voucher",
                  element: <CreatePaymentVoucher />,
                }
              : null,
              {
                path: "payment-voucher-details",
                element: <PaymntDetails />,
              }
            //  : null
          ].filter(Boolean),
        }
      : null,
    permissionsAccessFunction("role")
      ? {
          path: "roles",
          element: <MainLayout />,
          children: [
            allowedPermissions.includes("get-role")
              ? {
                  path: "all-roles",
                  element: <AllRoles />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,
    // : null
    permissionsAccessFunction("company")
      ? {
          path: "quotation",
          element: <MainLayout />,
          children: [
            allowedPermissions.includes("quotation.all")
              ? {
                  path: "all-quotations",
                  element: <AllQuotations />,
                }
              : null,

            // allowedPermissions.includes('register-company') || allowedPermissions.includes('edit-company')
            allowedPermissions.includes("quotation.register")
              ? {
                  path: "create-quotation",
                  element: <CreateQuotation />,
                }
              : null,
            allowedPermissions.includes("quotation.register")
              ? {
                  path: "view-quotation/:id",
                  element: <ViewSingleQuotation />,
                }
              : null,
            allowedPermissions.includes("quotation.get")
              ? {
                  path: "quotation-details",
                  element: <CreateCompany />,
                }
              : null,

            // : null
          ].filter(Boolean),
        }
      : null,

      /////...
      permissionsAccessFunction("company")
      ? {
          path: "recuring",
          element: <MainLayout />,
          children: [
            allowedPermissions.includes("quotation.all")
              ? {
                  path: "all-recuring-email",
                  element: <AllRecuringEmail />,
                }
              : null,

              allowedPermissions.includes("quotation.all")
              ? {
                  path: "create-recuring-email",
                  element: <CreaterecuringEmail />,
                }
              : null,

              

          ].filter(Boolean),
        }
      : null,

    // permissionsAccessFunction('company')
    // ?
    {
      path: "invoice",
      element: <MainLayout />,
      children: [
        allowedPermissions.includes("invoice.get")
          ? {
              path: "all-invoices",
              element: <AllInvoice />,
            }
          : null,

        // allowedPermissions.includes('register-company') || allowedPermissions.includes('edit-company')
        allowedPermissions.includes("invoice.store")
          ? {
              path: "create-invoice/:quotation_id",
              element: <CreateInvoice />,
            }
          : null,
        {
          path: "invoice-details",
          element: <InvoiceDetails />,
        },
        // : null
      ].filter(Boolean),
    },
    // : null

    // permissionsAccessFunction('company')
    // ?
    {
      path: "delivery-note",
      element: <MainLayout />,
      children: [
        allowedPermissions.includes("challan.get")
          ? {
              path: "all-challan",
              element: <AllChallan />,
            }
          : null,

        // allowedPermissions.includes('register-company') || allowedPermissions.includes('edit-company')
        allowedPermissions.includes("challan.store")
          ? {
              path: "create-challan",
              element: <CreateChallan />,
            }
          : null,
           {
            path: "challan-details/:id",
            element: <ChallanDetails />,
          }
       ,
      ].filter(Boolean),
    },
    // : null

    // permissionsAccessFunction('permission')
    // ?
    {
      path: "permissions",
      element: <MainLayout />,
      children: [
        allowedPermissions.includes("assign-permission")
          ? {
              path: "assign",
              element: <PermissionsAssign />,
            }
          : null,
      ].filter(Boolean),
    },

    {
      path: "userManagment",
      element: <MainLayout />,
      children: [
        allowedPermissions.includes("users.getAll")
          ? {
              path: "all-users",
              element: <AllUsers />,
            }
          : null,
       
      ].filter(Boolean),
    },

    {
      path: "reports",
      element: <MainLayout />,
      children: [
        allowedPermissions.includes("users.getAll")
          ? {
              path: "all-reports",
              element: <AllReports />,
            }
          : null,
       
      ].filter(Boolean),
    },

    {
      path: "reports",
      element: <MainLayout />,
      children: [
        allowedPermissions.includes("users.getAll")
          ? {
              path: "reports-by-company",
              element: <ReportsbyCompany/>,
            }
          : null,
       
      ].filter(Boolean),
    },
    {
      path: "reports",
      element: <MainLayout />,
      children: [
        allowedPermissions.includes("users.getAll")
          ? {
              path: "ledger",
              element: <Ledger/>,
            }
          : null,
       
      ].filter(Boolean),
    },
    

    
    {
      path: "reports",
      element: <MainLayout />,
      children: [
        allowedPermissions.includes("users.getAll")
          ? {
              path: "reports-by-clients",
              element: <ReportsbyClients/>,
            }
          : null,
       
      ].filter(Boolean),
    },

    
    {
      path: "user-management",
      element: <MainLayout />,
      children: [
        allowedPermissions.includes("users.getAll")
          ? {
              path: "register-user",
              element: <AllUsersRegister />,
            }
          : null,
       
      ].filter(Boolean),
    },


    
    // : null,

    // {
    //   path: 'delivery-note',
    //   element: <MainLayout />,
    //   children: [
    //     // allowedPermissions.includes('get-company')
    //        {
    //           path: 'all-challan',
    //           element: <AllChallan />
    //         },

    //     // allowedPermissions.includes('register-company') || allowedPermissions.includes('edit-company')
    //        {
    //           path: 'create-challan',
    //           element: <CreateChallan />
    //         }

    //       // : null
    //   ].filter(Boolean)
    // },
  ].filter(Boolean),
};

export default MainRoutes;
