// type
import { Setting, Colorfilter, Barcode } from 'iconsax-react';
import { AES, enc } from 'crypto-js';
import * as url from "../store/constant/Endpoints";
// icons
const icons = {
  navigation: Setting,
  typography: Setting,
  color: Colorfilter,
  shadow: Barcode
};
let permissionUser = localStorage.getItem('logindata');
let decryptedData = null;
if(permissionUser){
   decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(enc.Utf8);
}




      const permissions = JSON.parse(decryptedData);
   const allowedPermissions = permissions && permissions.length !== 0 ? permissions.map((permission) => permission?.permission) : [];
   const permissionsAccessFunction = (brand) => {
   
     return allowedPermissions && allowedPermissions.length !== 0 && allowedPermissions.some((permission) => permission.includes(brand));
   };


// ==============================|| MENU ITEMS - roles ||============================== //

const roles =
permissionsAccessFunction('role') ?
 {
  id: 'utilities',
  // title: 'Roles',
  icon: icons.navigation,
  type: 'group',
  children: [
 
   allowedPermissions.includes('get-role') ?
     {
      id: 'allroles',
      title: 'Roles',
      type: 'item',
      url: '/roles/all-roles',
      icon: icons.typography,
      breadcrumbs: true
    } 
 : null,
    
  ].filter(Boolean)
} 
: null;

export default roles;
