export const FETCH_LOGIN_DATA = 'FETCH_LOGIN_DATA'
export const FETCH_COMPANY = 'FETCH_COMPANY'
export const CUSTOMER_DATA = 'CUSTOMER_DATA'


export const FETCH_CURRENCY = 'FETCH_CURRENCY'
export const FETCH_DASHBOARD = 'FETCH_DASHBOARD'
export const REGISTER_COMPANY = 'REGISTER_COMPANY'
export const REGISTER_TAXES = 'REGISTER_TAXES'
export const FETCH_TAXES = 'FETCH_TAXES'
export const GET_CUSTOMER = 'GET_CUSTOMER'
export const NUMBER_FIELD = 'NUMBER_FIELD'
export const STORE_QUOTATION = 'STORE_QUOTATION'
export const UPDATE_QUOTATION = 'STORE_QUOTATION'
export const VIEW_QUOTATION = 'VIEW_QUOTATION'
export const VIEW_ROLES = 'VIEW_ROLES'
export const GETALL_PERMISSION = "GETALL_PERMISSION"
export const RECURING_ALL_DATA = "RECURING_ALL_DATA"
export const RECURING_FETCH = "RECURING_FETCH"
export const ALL_GET_REPORTS = "ALL_GET_REPORTS"
export const CUSTOMER_EDIT = "CUSTOMER_EDIT"
export const CUSTOMER_DELETE = "CUSTOMER_DELETE"
export const UPDATE_COMPANY = "UPDATE_COMPANY"
export const CREATE_ROLE = "CREATE_ROLE"
export const DELETE_COMPANY = "DELETE_COMPANY"
export const DELETE_CUSTOMER = "DELETE_CUSTOMER"
// ___________________________DASHBOARD_________________________
export const INVOICE_REPORTS = "INVOICE_REPORTS"
export const QUOTATIONS_REPORTS = "QUOTATIONS_REPORTS"
export const CHALLANS_REPORTS = "CHALLANS_REPORTS"
export const TOP_RATED_CUSTOMERS = "TOP_RATED_CUSTOMERS"
// ___________________________DASHBOARD_________________________
// __________________________________________________________________REPORTS_________________________________________________

export const LEDGER_REPORTS = "LEDGER_REPORTS"
export const INVOICE_ALL_EXPORT = "INVOICE_ALL_EXPORT"

export const VOUCHER_ALL_EXPORT = "VOUCHER_ALL_EXPORT"

export const QUOTATIONS_ALL_EXPORT = "QUOTATIONS_ALL_EXPORT"


export const CHALLAN_ALL_EXPORT = "CHALLAN_ALL_EXPORT"

export const QUOTATIONS_ALL_DELETE = "QUOTATIONS_ALL_DELETE"

export const INVOICE_ALL_DELETE = "INVOICE_ALL_DELETE"

export const CHALLAN_ALL_DELETE = "CHALLAN_ALL_DELETE"


export const VOUCHER_ALL_DELETE = "VOUCHER_ALL_DELETE"









// __________________________________________________________REPORTS__________________________________________________
export const DELETE_ACCOUNT = "DELETE_ACCOUNT"
export const UPDATE_TAXES = "UPDATE_TAXES"
export const DELETE_TAXES = "DELETE_TAXES"
export const CUSTOMER_REGISTER = "CUSTOMER_REGISTER"
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER"
export const EDIT_ACCOUNT = "EDIT_ACCOUNT"
export const CHART_ACCOUNT = "CHART_ACCOUNT"
export const REGISTER_ACCOUNT = "REGISTER_ACCOUNT"
export const INVOICE_REGISTER = "INVOICE_REGISTER"
export const CHALLAN_SEND = "CHALLAN_SEND"
export const MAIN_INVOICE = "MAIN_INVOICE"
export const MAIN_INVOICE_UPDATE = "MAIN_INVOICE_UPDATE"
export const QUOTATION_ALLGET = "QUOTATION_ALLGET"
export const INVOICE_ALLVIEW = "INVOICE_ALLVIEW"
// export const MAIN_DASHBOARD = "MAIN_DASHBOARD"
export const ALLUSERS = "ALLUSERS"
export const ALLUSERSDETAILS = "ALLUSERSDETAILS"
export const ALLCREATEUSERS = "ALLCREATEUSERS"
export const ALL_USER_DELETE = "ALL_USER_DELETE"



export const CHALLAN_ALLVIEW = "CHALLAN_ALLVIEW"
export const CHALLAN_DETAILS = "CHALLAN_DETAILS"
export const INVOICE_DETAILS = "INVOICE_DETAILS"
export const QUOTATION_DETAILS = "QUOTATION_DETAILS"
export const ALLQUOTATION_FILTER = "ALLQUOTATION_FILTER"
export const PAYMENT_VOUCHER = "PAYMENT_VOUCHER"
export const PAYMENT_VOUCHER_UPDATE = "PAYMENT_VOUCHER_UPDATE"
export const EXPENSE_VOUCHER = "EXPENSE_VOUCHER"
export const TEMPLATE_GET = "TEMPLATE_GET"
export const TEMPLATE_FETCH = "TEMPLATE_FETCH"
export const TEMPLATE_UPDATE = "TEMPLATE_UPDATE"
export const TEMPLATE_DELETE = "TEMPLATE_DELETE"
export const NEWTEMAE_DETAILS = "NEWTEMAE_DETAILS"
export const INVOICE_UPDATE = "INVOICE_UPDATE"
export const CHALLAN_UPDATE = "CHALLAN_UPDATE"
export const PAYMENTVOUCHER_VIEW = "PAYMENTVOUCHER_VIEW"
export const TERMS_SENDFETCH = "TERMS_SENDFETCH"
export const TERMS_ALLGET = "TERMS_ALLGET"
export const TERMS_ALLACTIVE = "TERMS_ALLACTIVE"
export const TERMS_EDIT = "TERMS_EDIT"
export const TERMS_DELETE = "TERMS_DELETE"
export const TERMS_DETAILS = "TERMS_DETAILS"
export const ASSIGN_PERMISSIONS = "ASSIGN_PERMISSIONS"
export const ROLE_PERMISSIONS = "ROLE_PERMISSIONS"
export const CHANGE_PASSWORD = "CHANGE_PASSWORD"
export const PAYMENT_VOUCHER_DATAILS = "PAYMENT_VOUCHER_DATAILS"
export const UPDATE_RECURING_EMAIL = "UPDATE_RECURING_EMAIL"







