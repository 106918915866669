
import {LEDGER_REPORTS} from "../../constant/ActionType";


export default function ViewAllLedger(state = {
    viewLedger: [],
    statuscode: null,
}, action) {
    switch (action.type) {
        case LEDGER_REPORTS:
            return {
                ...state,
                viewLedger: action.data,
                    statuscode: action.status
            };

        default:
    }

    return state;
}