import {
    INVOICE_ALL_EXPORT
} from "../../constant/ActionType";

export default function InvocieExportttReducer(state = {
    invoiceexppp: [],
    statusCode: null,
}, action) {
    switch (action.type) {
        case INVOICE_ALL_EXPORT:
            return {
                ...state,
                invoiceexppp: action.data,
                    statusCode: action.status
            };

        default:
    }
    return state;
}