import {QUOTATION_ALLGET} from "../../constant/ActionType";

export default function quotationGetAllReducer(state = {
    quotationget:[],
    statuscode: null,
}, action) {
    switch (action.type) {    
        case QUOTATION_ALLGET:
			return { ...state,
				quotationget: action.data ,
                statuscode:action.status
            };

        default:
    }
    return state;
}
