import {DELETE_CUSTOMER} from "../../constant/ActionType";

export default function DeleteCustomerReducer(state = {
    deleteCustomer:[],
    statusCode: null,
}, action) {
    switch (action.type) {    
        case DELETE_CUSTOMER:
			return { ...state,
				deleteCustomer: action.data ,
                statusCode:action.status
            };

        default:
    }
    return state;
}
