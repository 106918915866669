// type
import { ShopRemove } from "iconsax-react";
import { AES, enc } from "crypto-js";
import * as url from "../store/constant/Endpoints";
// icons
const icons = {
  navigation: ShopRemove,
  typography: ShopRemove,
  color: ShopRemove,
  shadow: ShopRemove,
};
// let permissionUser = localStorage.getItem("LoginData");
// let permissions = JSON.parse(permissionUser)?.permissions;

// const allowedPermissions = permissions && permissions.length !== 0 ? permissions.map(permission => permission?.permission) : [];

// const permissionsAccessFunction = (brand) => {
//   return allowedPermissions && allowedPermissions.length !== 0 && allowedPermissions.some(permission => permission.includes(brand));
// }

let permissionUser = localStorage.getItem("logindata");
let decryptedData = null;
if (permissionUser) {
  decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(
    enc.Utf8
  );
}

const permissions = JSON.parse(decryptedData);
const allowedPermissions =
  permissions && permissions.length !== 0
    ? permissions.map((permission) => permission?.permission)
    : [];
const permissionsAccessFunction = (brand) => {
  return (
    allowedPermissions &&
    allowedPermissions.length !== 0 &&
    allowedPermissions.some((permission) => permission.includes(brand))
  );
};

// ==============================|| MENU ITEMS - roles ||============================== //
let assignUserChecking = localStorage.getItem("userassigncompany");

const domainParameter =
  permissionsAccessFunction("company") ||
  permissionsAccessFunction("tax") ||
  permissionsAccessFunction("account") ||
  permissionsAccessFunction("templetes")
    ? {
        id: "utilities",
        title: "Domain Parameters",
        icon: icons.navigation,
        type: "group",
        children: [
          allowedPermissions.includes("company.getAll")
            ? {
                id: "all-company",
                title: "Company",
                type: "item",
                url: "/domain-parameter/all-company",
                icon: icons.typography,
                breadcrumbs: true,
              }
            : null,
          allowedPermissions.includes("company.getAll") &&
          assignUserChecking == "false"
            ? {
                id: "all-customer",
                title: "Customers",
                type: "item",
                url: "/domain-parameter/all-customer",
                icon: icons.typography,
                breadcrumbs: true,
              }
            : null,
          allowedPermissions.includes("tax.getAll") &&
          assignUserChecking == "false"
            ? {
                id: "all-taxes",
                title: "Taxes",
                type: "item",
                url: "/domain-parameter/all-taxes",
                icon: icons.typography,
                breadcrumbs: true,
              }
            : null,
          allowedPermissions.includes("account.getAll") &&
          assignUserChecking == "false"
            ? {
                id: "chart-of-account",
                title: "Chart Of Account",
                type: "item",
                url: "/domain-parameter/chart-of-account",
                icon: icons.typography,
                breadcrumbs: true,
              }
            : null,
          allowedPermissions.includes("terms.register") &&
          assignUserChecking == "false"
            ? {
                id: "terms-and-conditions",
                title: "Terms & Conditions",
                type: "item",
                url: "/domain-parameter/terms-and-conditions",
                icon: icons.typography,
                breadcrumbs: true,
              }
            : null,
          allowedPermissions.includes("templetes.get.all") &&
          assignUserChecking == "false"
            ? {
                id: "templates",
                title: "Templates",
                type: "item",
                url: "/domain-parameter/all-templates",
                icon: icons.typography,
                breadcrumbs: true,
              }
            : null,
        ].filter(Boolean),
      }
    : null;

export default domainParameter;
