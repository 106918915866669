// export const base_url = "http://localhost:8000/api/";
// export const temp_url = "http://localhost:8000/api/";
// export const downlaodpdfurl = "http://localhost:8000";

// export const base_url = `${window.location.protocol}/Cdn/public/api/`;
// export const temp_url = `${window.location.protocol}/Cdn/public/api/`;
// export const downlaodpdfurl = `${window.location.protocol}/Cdn/public`;


export const base_url = "https://qmasters.alisonstech-dev.com/Admin/api/";
export const temp_url = "https://qmasters.alisonstech-dev.com/Admin/api/";
export const downlaodpdfurl =
  "https://qmasters.alisonstech-dev.com/Admin/public";

// export const base_url = "https://qmastercdn.alisonstech-dev.com/Cdn/api/";
// export const temp_url = "https://qmastercdn.alisonstech-dev.com/Cdn/api/";
// export const downlaodpdfurl =
//   "https://qmastercdn.alisonstech-dev.com/Cdn/public";

// export const temp_url = "http://localhost:8000/api/";
// export const base_url = "http://localhost:8000/api/";
// export const downlaodpdfurl = "http://localhost:8000/public";

export const encryptionSecret = "lZk7QvGfTwAJjyWw5NdtmLF4";

export const logInApii = "login";
export const companyGetApi = "domainparmeter/company/active/all";

export const customerGetApii = "domainparmeter/customer/active/all";
export const customerEditApii = "domainparmeter/customer/getid?id=";
export const customerUpdateApii = "domainparmeter/customer/update";

export const customerDeleteApii = "domainparmeter/customer/delete";

export const cutomerrsGetApi = `domainparmeter/customer/active/all`;
export const recuringallget = "domainparmeter/EmailNotification/get/all";
export const registerCompany =
  "domainparmeter/company/register?company_id=" +
  localStorage.getItem("companyName");
export const taxesCompany = "domainparmeter/tax/active/all";
export const numberField = "quotation/getlastno";
export const storeQuatotion = "quotation/store";
export const updateQuatotion = "quotation/update";
export const viewQuatotion = "quotation/get/id";
export const updateCompany = "domainparmeter/company/update";
export const deleteCompany = "domainparmeter/company/delete";
export const deleteTaxes = "domainparmeter/tax/delete";
export const updateTaxes = "domainparmeter/tax/update";
export const editChartaccountt = "domainparmeter/account/update";
export const deleteAccountOFchart = "domainparmeter/account/delete";
export const taxRegister = "domainparmeter/tax/register";
export const customerRegister = "domainparmeter/customer/register";
export const customerEdit = "domainparmeter/customer/update";
export const customerDelete = "domainparmeter/customer/delete";
export const chartAccountGet = "domainparmeter/account/active/all";
export const registerAccountDataae = "domainparmeter/account/register";
export const invoiceSend = "invoice/storebyid";
export const AllViewQuatationss = "quotation/getAll";
export const invoicegetdata = "invoice/getAll";
export const challangetdata = "challan/getAll";
export const mainInvoice = "invoice/store";
export const challann = "challan/store";
export const challanupdatess = "challan/update";
export const invoicedetails = "invoice/get/id";
export const invoicesupdatee = "invoice/update";
export const challandetails = "challan/get/id";
export const quotationdetails = "quotation/get/id";
export const templatedertilss = "Templetes/get/id";
export const paymentapifetches = "PaymentVoucher/store";
export const expenseapifetches = "PaymentVoucher/expense/store";
export const paymentapiupdate = "PaymentVoucher/update";
export const templateregisteeerr = "Templetes/store";
export const paymentvoucherallgetet = "PaymentVoucher/getAll";
export const tempateAll = "Templetes/get/all";
export const tempalteeditt = "Templetes/update";
export const tempaltedeltee = "Templetes/delete";
export const fetchsendterm = "domainparmeter/terms/create";
export const termallGt = "domainparmeter/terms/get/all";
export const termallAct = "domainparmeter/terms/get/all/active";
export const getallinvoicescustomerid = "invoice/get/customer/id";
export const detailsTermms = "domainparmeter/terms/get/id";
export const termUpdatee = "domainparmeter/terms/update";
export const termDelete = "domainparmeter/terms/delete";
export const registerRollsApi = "Role/store";
export const editRoleAPI = "Role/Update";

export const allRoleApi = "Role/get";
export const assignpermissionsApi = "Permission/assign";
export const allpermissionsApi = "Permission/get";
export const getRolePermissionsApi = "Permission/get-rolepermissions/";
export const ChangePassword = "User/Password/change";
export const getCurrency = "domainparmeter/currency/get/active";
export const getDashboard = `Dashboard/get?company_id=${localStorage.getItem(
  "companyName"
)}`;
export const getPaymentVoucherDetail = "PaymentVoucher/get/";
export const fetchrecuringemail = "domainparmeter/EmailNotification/create";
export const updaterecuringemail = "domainparmeter/EmailNotification/update";

export const AllUsers = "user_management/getAll";
export const AllUsersDetails = "user_management/getId/";
export const CreateUsers = "user_management/create";
export const UpdateUsers = "user_management/update";
export const fetchrstatus = "user_management/update/status";
// _____________________________DASHBOARD___________________________________________
export const getAllinvoiceReportsApi = `reports/all/data/graph?company_id=${localStorage.getItem(
  "companyName"
)}`;
export const getAllquotationsReportsApi = "reports/quotations/reports";
export const getAllchallansReportsApi = `reports/chart/quotation?company_id=${localStorage.getItem(
  "companyName"
)}`;
export const getTopRatedCustomers = `reports/top_customers?company_id=${localStorage.getItem(
  "companyName"
)}`;
export const allreportApi = `reports/all/reports`;

export const getinvoiceexportsss = `invoice/getAll/export?paginate=all`;

export const getvoucherexportsss = `PaymentVoucher/getAll/export?paginate=all`;

export const getquotationsexportsss = `quotation/getAll/export?paginate=all`;

export const getchallansexportsss = `challan/getAll/export?paginate=all`;

export const quotationDelete = `quotation/delete`;

export const invoiceDelete = `invoice/delete`;

export const challanDelete = `challan/delete`;

export const voucherDeelete = `PaymentVoucher/delete`;

// _____________________________DASHBOARD___________________________________________

// ______________________________Report_____________________________________________
export const getAllledgerReportsApi = `reports/ledger/invoice?company_id=${localStorage.getItem(
  "companyName"
)}`;
export const fetchAllChartData = `reports/chart/all/charts?company_id=${localStorage.getItem(
  "companyName"
)}`;

// ___________________________REPORTS_________________________________________
