// material-ui

import MainCard from "components/MainCard";
import "assets/css/buttonHover.css";
import { onPageChange } from "helper/paginationHelper";
import { SearchQueryFormatter } from "helper/searchHelper";

import { Button } from "@mui/material";
import {
  editButton,
  deactivateButton,
  renderButton,
} from "components/actionButtons/actionsButtons";

import { Refresh2 } from "iconsax-react";
import { Table, Pagination, Spin } from "antd";
import { CSVLink } from "react-csv";
import { useEffect, useState } from "react";
import { connect } from "react-redux";

import { fetchChartOfAccount, DeleteChartAccount } from "store/action/index";

import { dispatch } from "store/index";
import { useNavigate } from "react-router-dom";
import { ChartOfAccountColumns } from "components/columns/columns";
// ==============================|| DASHBOARD - DEFAULT ||============================== //

const AllChartOfAccount = ({ chartAccount }) => {
  const navigate = useNavigate();
  const [status, setStatus] = useState(true);
  const [loading, setLoading] = useState(true);
  const [params, setparams] = useState({ page: 1 });

  useEffect(() => {
    dispatch(
      fetchChartOfAccount({
        params: SearchQueryFormatter(params),
        setLoading: setLoading,
      })
    );
  }, [params]);

  const handleEdit = (path, params) => {
    // dispatch(FetchCompanyDetails(params));
    navigate(path, { state: params });
  };

  const handleUpdateStatus = (userId) => {
    dispatch(DeleteChartAccount({ id: userId, setLoading: setLoading }));

    dispatch(fetchChartOfAccount({ setLoading: setLoading }));
  };

  const columns = ChartOfAccountColumns({ params, setparams });
  columns.push({
    title: "Actions",
    key: "actions",
    render: (text, record) => {
      return (
        <span>
          {editButton(
            "update-tax",
            "/domain-parameter/create-account",
            record,
            handleEdit
          )}
          {deactivateButton("deactivate-tax", record, handleUpdateStatus)}
        </span>
      );
    },
  });

  return (
    <MainCard>
      <div style={{ textAlign: "end" }}>
        <Button
          sx={{ display: "initial" }}
          onClick={() => {
            setLoading(true);
            dispatch(fetchChartOfAccount({ setLoading: setLoading }));
          }}
          size="medium"
          type="submit"
          variant="contained"
          color="primary"
          className="mx-3 refershButton active refreshButton"
        >
          <Refresh2
            className="refreshIcon"
            size="32"
            color="#ffffff"
            variant="TwoTone"
          />
        </Button>

        {renderButton(
          "create-account",
          "/domain-parameter/create-account",
          "Add Account"
        )}
      </div>

      <Spin spinning={loading}>
        <Table
          className="border rounded"
          style={{ marginTop: 25, overflowX: "auto" }}
          dataSource={chartAccount?.data ?? []}
          columns={columns}
          pagination={false}
          rowKey="id"
          components={{
            body: {
              cell: ({ style, ...restProps }) => {
                return (
                  <td style={{ ...style, padding: "15px" }} {...restProps} />
                );
              },
            },
          }}
        />
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 25 }}
        >
          {/* <Pagination
            total={Number(lastPage ? lastPage : 1) * 10}
            defaultCurrent={params.page}
            onChange={(page) => onPageChange(setparams, params, page)}
          /> */}
        </div>
      </Spin>
    </MainCard>
  );
};
const mapStateToProps = (state) => {
  return {
    chartAccount: state.chartofaccountt.chartAccount,
  };
};

export default connect(mapStateToProps)(AllChartOfAccount);
