import {GET_CUSTOMER} from "../../constant/ActionType";

export default function CustomerReducer(state = {
    customerData:[],
    statusCode: null,
}, action) {
    switch (action.type) {    
        case GET_CUSTOMER:
			return { ...state,
				customerData: action.data ,
                statusCode:action.status
            };

        default:
    }
    return state;
}
