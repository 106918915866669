import {ALLUSERSDETAILS} from "../../constant/ActionType";

export default function UsersDetails(state = {
    users:[],
    statusCode: null,
}, action) {
    switch (action.type) {    
        case ALLUSERSDETAILS:
			return { ...state,
				users: action.data?.data,
                statusCode:action.status
            };

        default:
    }
    return state;
}
