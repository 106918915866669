// type
import { ShopRemove } from "iconsax-react";
import { AES, enc } from "crypto-js";
import * as url from "../store/constant/Endpoints";
// icons
const icons = {
  navigation: ShopRemove,
  typography: ShopRemove,
  color: ShopRemove,
  shadow: ShopRemove,
};
// let permissionUser = localStorage.getItem("LoginData");
// let permissions = JSON.parse(permissionUser)?.permissions;

// const allowedPermissions = permissions && permissions.length !== 0 ? permissions.map(permission => permission?.permission) : [];

// const permissionsAccessFunction = (brand) => {
//   return allowedPermissions && allowedPermissions.length !== 0 && allowedPermissions.some(permission => permission.includes(brand));
// }

let permissionUser = localStorage.getItem("logindata");
let decryptedData = null;
if (permissionUser) {
  decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(
    enc.Utf8
  );
}

const permissions = JSON.parse(decryptedData);
const allowedPermissions =
  permissions && permissions.length !== 0
    ? permissions.map((permission) => permission?.permission)
    : [];
const permissionsAccessFunction = (brand) => {
  return (
    allowedPermissions &&
    allowedPermissions.length !== 0 &&
    allowedPermissions.some((permission) => permission.includes(brand))
  );
};

// ==============================|| MENU ITEMS - roles ||============================== //

const domainParameter =
  permissionsAccessFunction("company") ||
    permissionsAccessFunction("tax") ||
    permissionsAccessFunction("account") ||
    permissionsAccessFunction("templetes")
    ? {
      id: "utilities",
      title: "Reports",
      icon: icons.navigation,
      type: "group",
      children: [
        allowedPermissions.includes("company.getAll")
          ? {
            id: "all-reports",
            title: "Reports",
            type: "item",
            url: "/reports/all-reports",
            icon: icons.typography,
            breadcrumbs: true,
          }
          : null,
        // allowedPermissions.includes("tax.getAll")
        //   ? {
        //       id: "report-by-clients",
        //       title: "Reports By Clients",
        //       type: "item",
        //       url: "/reports/reports-by-clients",
        //       icon: icons.typography,
        //       breadcrumbs: true,
        //     }
        //   : null,
        // allowedPermissions.includes("account.getAll")
        //   ? {
        //       id: "report-by-company",
        //       title: "Reports By Company",
        //       type: "item",
        //       url: "/reports/reports-by-company",
        //       icon: icons.typography,
        //       breadcrumbs: true,
        //     }
        //   : null,
        allowedPermissions.includes("account.getAll")
          ? {
            id: "company_ledger",
            title: "Ledger",
            type: "item",
            url: "/reports/ledger",
            icon: icons.typography,
            breadcrumbs: true,
          }
          : null,
      ].filter(Boolean),
    }
    : null;

export default domainParameter;
