import PropTypes from "prop-types";
import { useMemo } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import { AppBar, Toolbar, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";

// project-imports
import AppBarStyled from "./AppBarStyled";
import HeaderContent from "./HeaderContent";

import IconButton from "components/@extended/IconButton";
import { DRAWER_WIDTH } from "config";

// assets
import { HambergerMenu } from "iconsax-react";
import { dispatch } from "../../../store/index";
import { fetchCompanyDataa, fetchDeleteCompany } from "store/action/index";
import { connect } from "react-redux";
import { Select as SelectAnt, Tooltip } from "antd";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Stack,
  OutlinedInput,
  Button,
} from "@mui/material";
// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const Header = ({ open, handleDrawerToggle, allcompany }) => {
  const theme = useTheme();
  const downLG = useMediaQuery(theme.breakpoints.down("lg"));
  // header content
  const headerContent = useMemo(() => <HeaderContent />, []);
  const [Filter_data, set_Filter_Data] = useState();
  const [restore_com, set_Restore_Cmp] = useState();
  const [showfielddta, set_Show_Data] = useState();

  const iconBackColorOpen = "secondary.200";
  const iconBackColor = "secondary.100";
  const sicpaMain = "sicpa.main";

  useEffect(() => {
    dispatch(fetchCompanyDataa());
  }, []);

  useEffect(() => {
    set_Restore_Cmp(localStorage.getItem("companyName"));
  }, [localStorage.getItem("companyName")]);

  useEffect(() => {
    if (allcompany) {
      let FilterData = allcompany?.filter((item) => item.company_type == "own");
      set_Filter_Data(FilterData);
    }
  }, [allcompany]);

  useEffect(() => {
    if (allcompany) {
      let FilterData = allcompany?.filter((item) => item.id == restore_com);
      set_Show_Data(FilterData[0]?.company_name);
    }
    console.log("ddddddddceeedd", allcompany);
  }, [restore_com, allcompany]);

  const CurrencyChangeHandle = (value) => {
    localStorage.setItem("companyName", value);
    set_Restore_Cmp((prev) => !prev);
    window.location.reload();
  };

  const currencyOption =
    Filter_data && Filter_data.length > 0
      ? Filter_data?.map((v) => ({ value: v.id, label: v.company_name }))
      : [];

  // common header
  const mainHeader = (
    <Toolbar style={{ px: { xs: 2, sm: 4.5, lg: 8 } }}>
      <IconButton
        aria-label="open drawer"
        onClick={handleDrawerToggle}
        edge="start"
        color="secondary"
        variant="light"
        size="large"
        style={{
          color: "red",
          bgcolor: open ? iconBackColorOpen : iconBackColor,
          ml: { xs: 0, lg: -2 },
          p: 1,
        }}
      >
        <HambergerMenu />
      </IconButton>
      <div style={{ marginLeft: "30px" }}>
        <Stack>
          {/* <Tooltip title="Select a Company"> */}
          <FormControl variant="outlined" fullWidth>
            {showfielddta && (
              <>
                <SelectAnt
                  showSearch
                  style={{
                    width: "180px",
                    height: "50px",
                    margin: "0px",
                  }}
                  value={showfielddta ?? ""}
                  onChange={CurrencyChangeHandle}
                  tokenSeparators={[","]}
                  options={currencyOption}
                  placeholder="Select Currency"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                />
                {!showfielddta && <CircularProgress size={50} />}
              </>
            )}
          </FormControl>
          {/* </Tooltip> */}
        </Stack>
      </div>
      {headerContent}
    </Toolbar>
  );

  // app-bar params
  const appBar = {
    position: "fixed",
    elevation: 0,
    sx: {
      bgcolor: sicpaMain,
      backdropFilter: "blur(8px)",
      zIndex: 1200,
      width: open ? `calc(100% - ${DRAWER_WIDTH}px)` : "100%",
    },
  };

  return (
    <>
      {!downLG ? (
        <AppBarStyled open={open} {...appBar}>
          {mainHeader}
        </AppBarStyled>
      ) : (
        <AppBar {...appBar}>{mainHeader}</AppBar>
      )}
    </>
  );
};

Header.propTypes = {
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    allcompany: state.company.companyData?.data,
  };
};

export default connect(mapStateToProps)(Header);
