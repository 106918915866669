import {CHALLAN_ALLVIEW} from "../../constant/ActionType";

export default function challangetAllReducer(state = {
    challanget:[],
    statuscode: null,
}, action) {
    switch (action.type) {    
        case CHALLAN_ALLVIEW:
			return { ...state,
				challanget: action.data.data ,
                statuscode:action?.status
            };

        default:
    }
    console.log("dddsds",action)
    return state;
}
