// type
import { Home3, Tag2, Colorfilter, Barcode } from 'iconsax-react';
import DescriptionIcon from '@mui/icons-material/Description';
import { AES, enc } from 'crypto-js';
import * as url from "../store/constant/Endpoints";
// icons
const icons = {
  navigation: Home3,
  typography: Tag2,
  color: Colorfilter,
  shadow: Barcode,
  invoice:DescriptionIcon,
};


let permissionUser = localStorage.getItem('logindata');
let decryptedData = null;
if(permissionUser){
   decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(enc.Utf8);
}




      const permissions = JSON.parse(decryptedData);
   const allowedPermissions = permissions && permissions.length !== 0 ? permissions.map((permission) => permission?.permission) : [];
   const permissionsAccessFunction = (brand) => {
   
     return allowedPermissions && allowedPermissions.length !== 0 && allowedPermissions.some((permission) => permission.includes(brand));
   };
    

// let permissionUser = localStorage.getItem("LoginData");
// let permissions = JSON.parse(permissionUser)?.permissions;

// const allowedPermissions = permissions && permissions.length !== 0 ? permissions.map(permission => permission?.permission) : [];

// const permissionsAccessFunction = (batch) => {
//   return allowedPermissions && allowedPermissions.length !== 0 && allowedPermissions.some(permission => permission.includes(batch));
// }

// ==============================|| MENU ITEMS - batch ||============================== //

const invoice =
 permissionsAccessFunction('invoice') ?
 {
  id: 'utilities',
  // title: 'Invoice',
  icon: icons.Tag2,
  type: 'group',
  children: [
   
allowedPermissions.includes('invoice.get') ? 
    {
      id: 'all-invoices',
      title: 'Invoices',
      type: 'item',
      url: '/invoice/all-invoices',
      icon:icons.invoice,
      breadcrumbs: true
    }:null,
    // {
    //   id: 'invoice-details',
    //   title: 'All Invoices',
    //   type: 'item',
    //   url: '/invoice/invoice-details',
    //   icon:Tag2,
    //   breadcrumbs: true
    // }
    // : null
    ,

    

  ].filter(Boolean)
} 
 : null;

export default invoice;
