import { PAYMENT_VOUCHER_DATAILS} from "../../constant/ActionType";

export default function PaymentVoucherDetailReducer(state = {
    paymentvoucherdetailget: [],
    statusCode: null,
}, action) {
    switch (action.type) {
        case PAYMENT_VOUCHER_DATAILS:
            return {
                ...state,
                paymentvoucherdetailget: action.data.data,
                    statusCode: action.status
            };
        default:
            console.log("newsswwwwww",action)
    }
    return state;
}