// type
import { Home3, Tag2, Colorfilter, Barcode } from 'iconsax-react';
import { AES, enc } from 'crypto-js';
import * as url from "../store/constant/Endpoints";
// icons
const icons = {
  navigation: Home3,
  typography: Tag2,
  color: Colorfilter,
  shadow: Barcode
};

// let permissionUser = localStorage.getItem("LoginData");
// let permissions = JSON.parse(permissionUser)?.permissions;

// const allowedPermissions = permissions && permissions.length !== 0 ? permissions.map(permission => permission?.permission) : [];

// const permissionsAccessFunction = (brand) => {
//   return allowedPermissions && allowedPermissions.length !== 0 && allowedPermissions.some(permission => permission.includes(brand));
// }



let permissionUser = localStorage.getItem('logindata');
let decryptedData = null;
if(permissionUser){
   decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(enc.Utf8);
}




      const permissions = JSON.parse(decryptedData);
   const allowedPermissions = permissions && permissions.length !== 0 ? permissions.map((permission) => permission?.permission) : [];
   const permissionsAccessFunction = (brand) => {
   
     return allowedPermissions && allowedPermissions.length !== 0 && allowedPermissions.some((permission) => permission.includes(brand));
   };

// ==============================|| MENU ITEMS - brand ||============================== //

const Recuring = permissionsAccessFunction('quotation') ? 
{
  id: 'utilities',
  // title: 'Recuring Email',
  icon: icons.Tag2,
  type: 'group',
  children: [
   
  allowedPermissions.includes('quotation.get') ? 
   {
      id: 'all-recuring-email',
      title: 'Recuring Email',
      type: 'item',
      url: '/recuring/all-recuring-email',
      icon:Tag2,
      breadcrumbs: true
    } : null,

  ].filter(Boolean)
} : null;

export default Recuring;
