import shop from "../shop/shop";
import * as types from "../constant/ActionType";




export const fssetchsogsinUser = (data) => ({
  type: types.FETCH_LOGIN_DATA,
  data
})

export const storequAoser = (data) => ({
  type: types.STORE_QUOTATION,
  data
})
export const updateQuotation = (data) => ({
  type: types.UPDATE_QUOTATION,
  data
})
export const viewQuotation = (data) => ({
  type: types.VIEW_QUOTATION,
  data
})

export const contantAllviewroless = (data) => ({
  type: types.VIEW_ROLES,
  data,
});
export const rolePermissions = (data) => ({
  type: types.ROLE_PERMISSIONS,
  data,
});
export const invoiceSmall = (data) => ({
  type: types.INVOICE_REGISTER,
  data
})

export const childALLPermisssions = (data) => ({
  type: types.GETALL_PERMISSION,
  data,
})

export const mainInvoice = (data) => ({
  type: types.MAIN_INVOICE,
  data
})
export const mainInvoiceUpdate = (data) => ({
  type: types.MAIN_INVOICE_UPDATE,
  data
})

export const fetchchildchallan = (data) => ({
  type: types.CHALLAN_SEND,
  data
})


export const fetchtemplatesmallss = (data) => ({
  type: types.TEMPLATE_GET,
  data
})

export const NewfetchUpdatedata = (data) => ({
  type: types.UPDATE_COMPANY,
  data
})

export const Childassigpermissions = (data) => ({
  type: types.ASSIGN_PERMISSIONS,
  data,
});

export const fetchEdittempplate = (data) => ({
  type: types.TEMPLATE_UPDATE,
  data
})

export const updatechildmail = (data) => ({
  type: types.UPDATE_RECURING_EMAIL,
  data
})



export const fetchdeletetempattyye = (data) => ({
  type: types.TEMPLATE_DELETE,
  data
})

export const NewfetcheditCustomerr = (data) => ({
  type: types.UPDATE_CUSTOMER,
  data
})

export const NewfetchDeletedata = (data) => ({
  type: types.DELETE_COMPANY,
  data
})

export const fetchremoveAccountof = (data) => ({
  type: types.DELETE_ACCOUNT,
  data
})

export const NewfetchDeletecustomerr = (data) => ({
  type: types.DELETE_CUSTOMER,
  data
})
// ____________________________________________DASHBOARD___________________________________________________________________
export const reportsAllinvoice = (data) => ({
  type: types.INVOICE_REPORTS,
  data
})

export const reportsAllquotations = (data) => ({
  type: types.QUOTATIONS_REPORTS,
  data
})

export const reportsAllchallans = (data) => ({
  type: types.CHALLANS_REPORTS,
  data
})
export const reportsGetTopRatedCustomers = (data) => ({
  type: types.TOP_RATED_CUSTOMERS,
  data
})



// ____________________________________________DASHBOARD___________________________________________________________________



// _________________________________REPORT_________________________________________________________________

export const reportsAllledger = (data) => ({
  type: types.LEDGER_REPORTS,
  data
})



export const exportinvoiceAll = (data) => ({
  type: types.INVOICE_ALL_EXPORT,
  data
})


export const exportvoucherAll = (data) => ({
  type: types.VOUCHER_ALL_EXPORT,
  data
})



export const exportquotationAll = (data) => ({
  type: types.QUOTATIONS_ALL_EXPORT,
  data
})

export const exportchallanAll = (data) => ({
  type: types.CHALLAN_ALL_EXPORT,
  data
})


export const Deletequotation = (data) => ({
  type: types.QUOTATIONS_ALL_DELETE,
  data
})


export const Deleteinvoice = (data) => ({
  type: types.INVOICE_ALL_DELETE,
  data
})



export const Deletechallan = (data) => ({
  type: types.CHALLAN_ALL_DELETE,
  data
})


export const Deletevoucher = (data) => ({
  type: types.VOUCHER_ALL_DELETE,
  data
})




// ___________________________________REPORTS________________________________________________________________________________

export const fetchTaxdelete = (data) => ({
  type: types.DELETE_TAXES,
  data
})

export const fetchTaxupdate = (data) => ({
  type: types.UPDATE_TAXES,
  data
})

export const fetchupdateAccountChart = (data) => ({
  type: types.EDIT_ACCOUNT,
  data
})

export const fetchregistercompany = (data) => ({
  type: types.REGISTER_COMPANY,
  data
})

export const fetchregistertax = (data) => ({
  type: types.REGISTER_TAXES,
  data
})

export const accountregisterr = (data) => ({
  type: types.REGISTER_ACCOUNT,
  data
})

export const fetchregistercustomere = (data) => ({
  type: types.CUSTOMER_REGISTER,
  data
})

export const PostChangePassword = (data) => ({
  type: types.CHANGE_PASSWORD,
  data,
});

export const paymentsmallvouch = (data) => ({
  type: types.PAYMENT_VOUCHER,
  data
});
export const expensesmallvouch = (data) => ({
  type: types.EXPENSE_VOUCHER,
  data
});
export const update_paymentsmallvouch = (data) => ({
  type: types.PAYMENT_VOUCHER_UPDATE,
  data
})

export const viewsmallPaymentsses = (data) => ({
  type: types.PAYMENTVOUCHER_VIEW,
  data
})

export const fetchallanDetail = (data) => ({
  type: types.CHALLAN_DETAILS,
  data
})

export const detchvocieDetail = (data) => ({
  type: types.INVOICE_DETAILS,
  data
})

export const fetchuotationDetail = (data) => ({
  type: types.QUOTATION_DETAILS,
  data
})

export const smallfetchtemdetai = (data) => ({
  type: types.NEWTEMAE_DETAILS,
  data
})


export const fetchComapny = (data) => ({
  type: types.FETCH_COMPANY,
  data
})

export const CustomerDataEdit = (data) => ({
  type: types.CUSTOMER_EDIT,
  data
})

export const CustomerDataDelete = (data) => ({
  type: types.CUSTOMER_DELETE,
  data
})

export const CustomerData = (data) => ({
  type: types.CUSTOMER_DATA,
  data
})





export const fetchCurrencyData = (data) => ({
  type: types.FETCH_CURRENCY,
  data
})

export const fetchDasboardData = (data) => ({
  type: types.FETCH_DASHBOARD,
  data
})
export const PaymentVoucherDetailData = (data) => ({
  type: types.PAYMENT_VOUCHER_DATAILS,
  data
})

export const fetchTaxess = (data) => ({
  type: types.FETCH_TAXES,
  data
})

export const fetchNumberfield = (data) => ({
  type: types.NUMBER_FIELD,
  data
})

export const specificroless = (data) => ({
  type: types.CREATE_ROLE,
  data,
})

export const fetchCustomerr = (data) => ({
  type: types.GET_CUSTOMER,
  data
})

export const fetchquatIons = (data) => ({
  type: types.QUOTATION_ALLGET,
  data
})

export const fetchUsersAll = (data) => ({
  type: types.ALLUSERS,
  data
})

export const detailsUsersAll = (data) => ({
  type: types.ALLUSERSDETAILS,
  data
})

export const deleteUsersAll = (data) => ({
  type: types.ALL_USER_DELETE,
  data
})




export const createUsersAll = (data) => ({
  type: types.ALLCREATEUSERS,
  data
})

export const fetchsmallInvoice = (data) => ({
  type: types.INVOICE_ALLVIEW,
  data
})

// export const fetchmainDash = (data) => ({
//   type: types.MAIN_DASHBOARD,
//   data
// })


export const fetchsmallChallan = (data) => ({
  type: types.CHALLAN_ALLVIEW,
  data
})

export const fetchAccountt = (data) => ({
  type: types.CHART_ACCOUNT,
  data
})


export const fetcheditchallan = (data) => ({
  type: types.CHALLAN_UPDATE,
  data
})

export const fetchinvoiceeidt = (data) => ({
  type: types.INVOICE_UPDATE,
  data
})



export const childtermconiupdatte = (data) => ({
  type: types.TERMS_EDIT,
  data
})


export const condtiotermdlete = (data) => ({

  type: types.TERMS_DELETE,
  data
})

export const fetchchildsenregistr = (data) => ({
  type: types.TERMS_SENDFETCH,
  data
})


export const temrscidntiondetail = (data) => ({
  type: types.TERMS_DETAILS,
  data
})

export const termsconditionssalllll = (data) => ({
  type: types.TERMS_ALLGET,
  data
})


export const termsconditionssactivee = (data) => ({
  type: types.TERMS_ALLACTIVE,
  data
})


export const fetchchildtempregi = (data) => ({
  type: types.TEMPLATE_FETCH,
  data
})


export const recuringChild = (data) => ({
  type: types.RECURING_ALL_DATA,
  data
})

export const createRedChild = (data) => ({
  type: types.RECURING_FETCH,
  data
})

export const typeCheckreports = (data) => ({
  type: types.ALL_GET_REPORTS,
  data
})



export const FetchGetAllReports = (data) => (dispatch) => {
  shop.FetchGetAllReports(data, e => {
    dispatch(typeCheckreports(e, data))
  });
}

export const fetchCompanyDataa = (data) => (dispatch) => {
  shop.fetchCompanyDataa(data, e => {
    dispatch(fetchComapny(e, data))
  });
}


export const CustomerDataa = (data) => (dispatch) => {
  shop.CustomerrrDataa(data, e => {
    dispatch(CustomerData(e, data))
  });
}

export const CustomerSpecificUserEdit= (data) => (dispatch) => {
  shop.CustomerrrEditDataa(data, e => {
    dispatch(CustomerDataEdit(e, data))
  });
}

export const CustomerUserDelete= (data) => (dispatch) => {
  shop.CustomerrrDeleteDataa(data, e => {
    dispatch(CustomerDataDelete(e, data))
  });
}




export const FetchRecuringData = (data) => (dispatch) => {
  shop.FetchRecuringData(data, e => {
    dispatch(recuringChild(e, data))
  });
}

export const CreateRecuringFetch = (data) => (dispatch) => {
  shop.CreateRecuringFetch(data, e => {
    dispatch(createRedChild(e, data))
  });
}





export const fetchCurrency = (data) => (dispatch) => {
  shop.fetchCurrency(data, e => {
    dispatch(fetchCurrencyData(e, data))
  });
}

export const fetchDashboard = (data) => (dispatch) => {
  shop.fetchDashboard(data, e => {
    dispatch(fetchDasboardData(e, data))
  });
}
export const PaymentVoucherDetail = (data) => (dispatch) => {
  shop.PaymentVoucherDetail(data, e => {
    dispatch(PaymentVoucherDetailData(e, data))
  });
}

export const fetchTaxesComp = (data) => (dispatch) => {
  shop.fetchTaxesComp(data, e => {
    dispatch(fetchTaxess(e, data))

  });
}


export const fetchNumberField = (data) => (dispatch) => {
  shop.fetchNumberField(data, e => {
    dispatch(fetchNumberfield(e, data))
  });
}

export const fetchCustomer = (data) => (dispatch) => {
  shop.fetchCustomer(data, e => {
    dispatch(fetchCustomerr(e, data))
  });
}


export const fetchQuoatationGetAll = (data) => (dispatch) => {
  shop.fetchQuoatationGetAll(data, e => {
    dispatch(fetchquatIons(e, data))
  });
}

export const fetchUsersGetAll = (data) => (dispatch) => {
  shop.fetchUsersGetAll(data, e => {
    dispatch(fetchUsersAll(e, data))
  });
}


export const detailsUsersGetAll = (data) => (dispatch) => {
  shop.detailsUsersGetAll(data, e => {
    dispatch(detailsUsersAll(e, data))
  });
}

export const detailsUsersDelete = (data) => (dispatch) => {
  shop.detailsUsersDeleteAll(data, e => {
    dispatch(deleteUsersAll(e, data))
  });
}



export const CreateUsersAll = (data) => (dispatch) => {
  // console.log("ghghghgh",data);
  shop.CreateAllUsers(data, e => {
    dispatch(createUsersAll(e, data))
  });
}







export const fetchAllInvoice = (data) => (dispatch) => {
  shop.fetchAllInvoice(data, e => {
    dispatch(fetchsmallInvoice(e, data))
  });
}

export const fetchAllChallan = (data) => (dispatch) => {
  shop.fetchAllChallan(data, e => {
    dispatch(fetchsmallChallan(e, data))
  });
}

export const fetchChartOfAccount = (data) => async (dispatch) => {
  try {
    const result = await new Promise((resolve, reject) => {
      shop.fetchChartOfAccount(data, (e) => {
        resolve(e);
      });
    });

    dispatch(fetchAccountt(result, data));
  } catch (error) {
  }
}

export const fetchLoginUser = (data) => (dispatch) => {
  shop.fetchLoginUser(data, e => {
    dispatch(fssetchsogsinUser(e, data))
  });
}


export const fetchStoreQuotation = (data) => (dispatch) => {
  shop.fetchStoreQuotation(data, e => {
    dispatch(storequAoser(e, data))
  });
}

export const updateStoreQuotation = (data) => (dispatch) => {
  shop.updateStoreQuotation(data, e => {
    dispatch(updateQuotation(e, data))
  });
}
export const fetchViewQuotation = (data) => (dispatch) => {
  shop.fetchViewQuotation(data, e => {
    dispatch(viewQuotation(e, data))
  });
}

export const fetchInvoiceRegisterr = (data) => (dispatch) => {
  shop.fetchInvoiceRegisterr(data, e => {
    dispatch(invoiceSmall(e, data))
  });
}

export const fetchMainInvoices = (data) => (dispatch) => {
  shop.fetchMainInvoices(data, e => {
    dispatch(mainInvoice(e, data))
  });
}
export const updateMainInvoice = (data) => (dispatch) => {
  shop.updateMainInvoices(data, e => {
    dispatch(mainInvoiceUpdate(e, data))
  });
}

export const fetchChallan = (data) => (dispatch) => {
  shop.fetchChallan(data, e => {
    dispatch(fetchchildchallan(e, data))
  });
}

export const fetchUpdateCompany = (data) => (dispatch) => {
  shop.fetchUpdateCompany(data, e => {
    dispatch(NewfetchUpdatedata(e, data))

  });
}

export const DeleteChartAccount = (data) => (dispatch) => {
  shop.DeleteChartAccount(data, e => {
    dispatch(fetchremoveAccountof(e, data))

  });
}

export const CreateFetchRolee = (data) => (dispatch) => {
  shop.CreateFetchRolee(data, (e) => {
    dispatch(specificroless(e, data));
  });
};

export const fetchEditCompany = (data) => (dispatch) => {
  shop.fetchUpdateCompany(data, e => {
    dispatch(NewfetcheditCustomerr(e, data))
  });
}

export const fetchInvoieWditt = (data) => (dispatch) => {
  shop.fetchInvoieWditt(data, e => {
    dispatch(fetchinvoiceeidt(e, data))
  });
}

export const EduitChallabn = (data) => (dispatch) => {
  shop.EduitChallabn(data, e => {
    dispatch(fetcheditchallan(e, data))
  });
}

export const fetchUpdateTaxes = (data) => (dispatch) => {
  shop.fetchUpdateTaxes(data, e => {
    dispatch(fetchTaxupdate(e, data))

  });
}

export const fetchEditAccounts = (data) => (dispatch) => {
  shop.fetchEditAccounts(data, e => {
    dispatch(fetchupdateAccountChart(e, data))
  });
}


export const fetchDeleteCompany = (data) => (dispatch) => {
  shop.fetchDeleteCompany(data, e => {
    dispatch(NewfetchDeletedata(e, data))
  });
}

export const Delete = (data) => (dispatch) => {
  shop.Delete(data, e => {
    dispatch(fetchremoveAccountof(e, data))
  });
}

export const fetchDeleteCustomer = (data) => (dispatch) => {
  shop.fetchDeleteCustomer(data, e => {
    dispatch(NewfetchDeletecustomerr(e, data))
  });
}

export const fetchDeleteTax = (data) => (dispatch) => {
  shop.fetchDeleteTax(data, e => {
    dispatch(fetchTaxdelete(e, data))
  });
}

export const fetchRegisterCompany = (data) => (dispatch) => {
  shop.fetchRegisterCompany(data, e => {
    dispatch(fetchregistercompany(e, data))
  });
}


export const fetchRegisterTaxxx = (data) => (dispatch) => {
  shop.fetchRegisterTaxxx(data, e => {
    dispatch(fetchregistertax(e, data))
  });
}

export const fetchRegistereacccount = (data) => (dispatch) => {
  shop.fetchRegistereacccount(data, e => {
    dispatch(accountregisterr(e, data))
  });
}

export const fetchRegisterCustomerrr = (data) => (dispatch) => {
  shop.fetchRegisterCustomerrr(data, e => {
    dispatch(fetchregistercustomere(e, data))
  });
}


export const detchViewChallanDetails = (data) => (dispatch) => {
  shop.detchViewChallanDetails(data, e => {
    dispatch(fetchallanDetail(e, data))
  });
}


export const fetchNEwDEtailsQuoata = (data) => (dispatch) => {
  shop.fetchNEwDEtailsQuoata(data, e => {
    dispatch(fetchuotationDetail(e, data))
  });
}


export const fetchHandleTemplateDetails = (data) => (dispatch) => {
  shop.fetchHandleTemplateDetails(data, e => {
    dispatch(smallfetchtemdetai(e, data))
  });
}


export const fetchInvoiceDetails = (data) => (dispatch) => {
  shop.fetchInvoiceDetails(data, e => {
    dispatch(detchvocieDetail(e, data))
  });
}


export const fetchPaymentVocuherr = (data) => (dispatch) => {
  shop.fetchPaymentVocuherr(data, e => {
    dispatch(paymentsmallvouch(e, data))
  });
}
export const fetchExpenseVocuherr = (data) => (dispatch) => {
  shop.fetchExpenseVocuherr(data, e => {
    dispatch(expensesmallvouch(e, data))
  });
}
export const updatePaymentVocuherr = (data) => (dispatch) => {
  shop.updatePaymentVocuherr(data, e => {
    dispatch(update_paymentsmallvouch(e, data))
  });
}

export const tchpaymentVoucherVieww = (data) => (dispatch) => {
  shop.tchpaymentVoucherVieww(data, e => {
    dispatch(viewsmallPaymentsses(e, data))
  });
}


export const fetchTemplateGett = (data) => (dispatch) => {
  shop.fetchTemplateGett(data, e => {
    dispatch(fetchtemplatesmallss(e, data))
  });
}



export const fetchEditttTemplatee = (data) => (dispatch) => {
  shop.fetchEditttTemplatee(data, e => {
    dispatch(fetchEdittempplate(e, data))
  });
}

export const EditREcuringMail = (data) => (dispatch) => {
  shop.EditREcuringMail(data, e => {
    dispatch(updatechildmail(e, data))
  });
}




export const fetchDeleteeyTemplatess = (data) => (dispatch) => {
  shop.fetchDeleteeyTemplatess(data, e => {
    dispatch(fetchdeletetempattyye(e, data))
  });
}


export const fetchAllVwwTrmsoitins = (data) => (dispatch) => {
  shop.fetchAllVwwTrmsoitins(data, e => {
    dispatch(termsconditionssalllll(e, data))
  });
}

export const fetchAllTrmActive = (data) => (dispatch) => {
  shop.fetchAllTrmActive(data, e => {
    dispatch(termsconditionssactivee(e, data))
  });
}




export const fetchtemrsCotitonsEdit = (data) => (dispatch) => {
  shop.fetchtemrsCotitonsEdit(data, e => {
    dispatch(childtermconiupdatte(e, data))
  });
}


export const fetchTermcoftioDetailsseses = (data) => (dispatch) => {
  shop.fetchTermcoftioDetailsseses(data, e => {
    dispatch(temrscidntiondetail(e, data))
  });
}



export const fetchTrmsCotntionDeletee = (data) => (dispatch) => {
  shop.fetchTrmsCotntionDeletee(data, e => {

    dispatch(condtiotermdlete(e, data))
  });
}

export const FetchAllRoleView = (data) => (dispatch) => {
  // const history=useHistory()
  shop.FetchAllRoleView(data, (e) => {
    dispatch(contantAllviewroless(e, data));
  });
};

export const EditRole = (data) => (dispatch) => {
  shop.EditRole(data, (e) => {
    dispatch(specificroless(e, data));
  });
};

export const fetchTermsCondtiosnsn = (data) => (dispatch) => {
  shop.fetchTermsCondtiosnsn(data, e => {
    dispatch(fetchchildsenregistr(e, data))
  });
}

export const ChangePassword = (data) => (dispatch) => {
  shop.ChangePassword(data, (e) => {
    dispatch(PostChangePassword(e, data));
  });
};

export const AssignPermissionsShop = (data) => (dispatch) => {
  // const history=useHistory()
  shop.AssignPermissionsShop(data, (e) => {
    dispatch(Childassigpermissions(e, data));
  });
};

export const getAllPermissions = (data) => (dispatch) => {
  // const history=useHistory()
  shop.getAllPermissions(data, (e) => {
    dispatch(childALLPermisssions(e, data));
  });
};


export const fetchTemplateSend = (data) => (dispatch) => {
  shop.fetchTemplateSend(data, e => {
    dispatch(fetchchildtempregi(e, data))

  });
}



export const GetRolePermissions = (data) => (dispatch) => {
  shop.GetRolePermissions(data, (e) => {
    dispatch(rolePermissions(e, data));
  });
};


// ______________________________DASHBOARD_____________________________________________________________

export const GetinvoiceReports = (data) => (dispatch) => {
  shop.GetAllinvoiceReports(data, (e) => {
    dispatch(reportsAllinvoice(e, data));
  });
};


export const GetquotationsReports = (data) => (dispatch) => {
  shop.GetAllquotationsReports(data, (e) => {
    dispatch(reportsAllquotations(e, data));
  });
};

export const GetchallansReports = (data) => (dispatch) => {
  shop.GetAllchallansReports(data, (e) => {
    dispatch(reportsAllchallans(e, data));
  });
};
export const GetTopRatedCustomersData = (data) => (dispatch) => {
  shop.GetTopRatedCustomers(data, (e) => {
    dispatch(reportsGetTopRatedCustomers(e, data));
  });
};



// ______________________________DASHBOARD____________________________________________________________


// _________________________________REPORTS________________________________________________________________

export const GetledgerReports = (data) => (dispatch) => {
  shop.GetAllledgerReports(data, (e) => {
    dispatch(reportsAllledger(e, data));
  });
};



export const ExportAllInvoices = (data) => (dispatch) => {
  shop.AllInvoiceExp(data, (e) => {
    dispatch(exportinvoiceAll(e, data));
  });
};


export const ExportAllVoucher = (data) => (dispatch) => {
  shop.AllVoucherExp(data, (e) => {
    dispatch(exportvoucherAll(e, data));
  });
};


export const ExportAllQuotations= (data) => (dispatch) => {
  shop.AllQuotationsExp(data, (e) => {
    dispatch(exportquotationAll(e, data));
  });
};



export const ExportAllChallans= (data) => (dispatch) => {
  shop.AllChallanExp(data, (e) => {
    dispatch(exportchallanAll(e, data));
  });
};





export const QuotDelete= (data) => (dispatch) => {
  shop.AllQuotationDel(data, (e) => {
    dispatch(Deletequotation(e, data));
  });
};


export const InvoiceDelete= (data) => (dispatch) => {
  shop.AllInvoiceDel(data, (e) => {
    dispatch(Deleteinvoice(e, data));
  });
};


export const challanDelete= (data) => (dispatch) => {
  shop.AllChallanDel(data, (e) => {
    dispatch(Deletechallan(e, data));
  });
};


export const voucherDelete= (data) => (dispatch) => {
  shop.AllVoucherDel(data, (e) => {
    dispatch(Deletevoucher(e, data));
  });
};






// ____________________________________REPORTS_________________________________________________________________