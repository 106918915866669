import {INVOICE_DETAILS} from "../../constant/ActionType";

export default function invoicessDetailReducer(state = {
    invoicedetails:[],
    statusCode: null,
}, action) {
    switch (action.type) {    
        case INVOICE_DETAILS:
			return { ...state,
				invoicedetails: action.data ,
                statusCode:action.status
            };

        default:
    }
    return state;
}
