import { VOUCHER_ALL_EXPORT} from "../../constant/ActionType";

export default function VoucherExportReducer(state = {
    voucherexppp: [],
    statusCode: null,
}, action) {
    switch (action.type) {
        case VOUCHER_ALL_EXPORT:
            return {
                ...state,
                voucherexppp: action.data.data,
                    statusCode: action.status
            };
        default:
            console.log("newsswwwwww",action)
    }
    return state;
}