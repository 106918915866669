import {CUSTOMER_DATA} from "../../constant/ActionType";

export default function CustomerrReducer(state = {
    customerData:[],
    statusCode: null,
}, action) {
    switch (action.type) {    
        case CUSTOMER_DATA:
			return { ...state,
				customerData: action?.data ,
                statusCode:action.status
            };

        default:
    }
    return state;
}
