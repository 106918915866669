import {
    INVOICE_UPDATE
} from "../../constant/ActionType";

export default function InvocieUodateReducer(state = {
    invoiceupdayee: [],
    statusCode: null,
}, action) {
    switch (action.type) {
        case INVOICE_UPDATE:
            return {
                ...state,
                invoiceupdayee: action.data,
                    statusCode: action.status
            };

        default:
    }
    return state;
}