// third-party
import { combineReducers } from "redux";

// project-imports
import menu from "./menu";
// Import custom components
import LoginnReducer from './authreducer/login';
import CompanyReducer from './companyreducer/comapany'
import CustomerrReducer from './companyreducer/customerdata'
import CurrencyReducer from './currencyreducer/currency'
import TaxesReducer from './taxreducer/taxes'
import CustomerReducer from './customerreducer/customer'
import NumberFieldCusrReducer from './numberfieldcustomer'
import DeleteCustomerReducer from './customerreducer/deletecustomer'
import editCustomerReducer from './customerreducer/editcustomer'
import ChrAccountReducer from './chartofaccountreducer/chartaccount'
import quotationGetAllReducer from './quotationreducer/quotationgetall'
import quotationViewAllReducer from './quotationreducer/quotationview'
import challangetAllReducer from './deliverynotereducer/allchallan'
import invoicegetAllReducer from './invoicereducer/allinvoice'
import dashboardtAllReducer from './dashboardreducer/dashboard'
import quotationssActiveDetail from './quotationreducer/quotationactive'

import ViewAllRolesReduncerre from './rolereducer/viewallrole'
import GetAllPermissionReducer from './getallassignpermissions'
import CustomerrEditReducer from './companyreducer/customerEdit'


import quotationssDetailReducer from './quotationreducer/quotationdetails'
import challanDetailReducer from './deliverynotereducer/challandetails'
import invoicessDetailReducer from './invoicereducer/invoicedetails'
import PaymentVoucherViewiReducer from './paymentvoucherreducer/paymentvoucheralldata'
import PaymentVoucherDetailReducer from './paymentvoucherreducer/PaymentVoucherDetail'


import TemplateGettingResdunerr from './templatereducer/templateget'
import InvocieUodateReducer from './invoicereducer/invoiceupdate'
import TemplateDestailssReducer from './templatereducer/templatedetailsss'
import TermViewAllReducer from './termconditionreducer/termsallview'
import TermsDetailsAlReducer from './termconditionreducer/termsdetails'
import AllViewRecuring from './recuringemail/alldatarecuringemail'

import UsersReducer from './userreducer/userManagment'
import UsersDetails from './userreducer/alluserDetails'
import UsersCreate from './userreducer/createUser'


// ___________________________________DASHBOARD_____________________________________

import InvoiceReports from './dashboardreducer/invoicereports'
import ChallanReports from './dashboardreducer/challanreports'
import QuotationsReports from './dashboardreducer/quotationsreports'
// ___________________________________DASHBOARD_____________________________________

import ViewAllLedger from './reportsreducer/ledger'
import dashboardallTopRatedCustomer from './dashboardreducer/topratedcutomers'
import AllREportGetRender from './reportsreducer/allreports'

import InvocieExportttReducer from './invoicereducer/invoiceexports'
import VoucherExportReducer from './paymentvoucherreducer/voucherexportsss'
import quotationExportReducer from "./quotationreducer/quotationsexports"
import challanExportReducer from "./deliverynotereducer/challanexportss"


// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  logindata: LoginnReducer,
  company: CompanyReducer,
  taxesdata: TaxesReducer,
  customerdata: CustomerReducer,
  customernumberfield: NumberFieldCusrReducer,
  deletecustomer: DeleteCustomerReducer,
  editCustomer: editCustomerReducer,
  chartofaccountt: ChrAccountReducer,
  quotationall: quotationGetAllReducer,
  quotationView: quotationViewAllReducer,
  getchallan: challangetAllReducer,
  getinvoiceall: invoicegetAllReducer,
  CustomAllDataDashboard: dashboardtAllReducer,
  quotationdetails: quotationssDetailReducer,
  challandetails: challanDetailReducer,
  invoicDetailess: invoicessDetailReducer,
  paymentvouchershow: PaymentVoucherViewiReducer,
  templateGet: TemplateGettingResdunerr,
  invoiceUpdate: InvocieUodateReducer,
  templateDetails: TemplateDestailssReducer,
  termsAlling: TermViewAllReducer,
  termdatilss: TermsDetailsAlReducer,
  roleReducer: ViewAllRolesReduncerre,
  topRatedCustomerReducer: dashboardallTopRatedCustomer,

  mainviewrecuring: AllViewRecuring,
  permissionReducer: GetAllPermissionReducer,
  currency: CurrencyReducer,
  paymentDeatils: PaymentVoucherDetailReducer,
  UsersReducer: UsersReducer,
  UsersDetails: UsersDetails,
  UsersCreate: UsersCreate,
  InvoiceReports: InvoiceReports,
  ChallanReports: ChallanReports,
  QuotationsReports: QuotationsReports,
  ViewAllLedger: ViewAllLedger,
  AllREportGetRender,
  CustomerrReducer:CustomerrReducer,
  quotationssActiveDetail,
  CustomerrEditReducer:CustomerrEditReducer,
  InvocieExportttReducer:InvocieExportttReducer,
  VoucherExportReducer:VoucherExportReducer,
  quotationExportReducer:quotationExportReducer,
  challanExportReducer:challanExportReducer,


});

export default reducers;
