import {TERMS_ALLACTIVE} from "../../constant/ActionType";

export default function quotationssActiveDetail(state = {
    quotationsssactive:[],
    statusCode: null,
}, action) {
    switch (action.type) {    
        case TERMS_ALLACTIVE:
			return { ...state,
				quotationsssactive: action.data ,
                statusCode:action.status
            };

        default:
    }
    return state;
}
