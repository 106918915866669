import MainCard from "components/MainCard";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { downlaodpdfurl } from "store/constant/Endpoints";
import AddCompanyModal from "components/modals/addcompnaymodal";
import ThemeInput from "components/inputs/ThemeInput";
import { useEffect, useRef, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { UploadOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import ReactQuill from "react-quill";
import {
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Stack,
  OutlinedInput,
  Button,
} from "@mui/material";
import { Spin, Table, Space, Input, Modal, Upload } from "antd";
import { dispatch } from "store/index";
import {
  fetchRegisterCompany,
  fetchAllChallan,
  fetchAllInvoice,
  fetchQuoatationGetAll,
  fetchTemplateGett,
  fetchAllVwwTrmsoitins,
  fetchEditCompany,
  fetchNumberField,
  fetchCustomer,
  fetchCompanyDataa,
  fetchStoreQuotation,
  fetchTaxesComp,
  fetchCurrency,
  fetchViewQuotation,
  updateStoreQuotation,
  fetchAllTrmActive,
} from "store/action/index";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { Select as SelectAnt } from "antd";
import draftToHtml from "draftjs-to-html";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

import { EditorState, ContentState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import TaxOption from "components/taxOption";
import SearchTextSelect from "components/selects/SearchTextSelect";
import { log } from "../../../node_modules/util/util";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";

// ==============================|| Create / Edit Company - SICPA ||============================== //

const CreateQuotation = ({
  quotationsssactive,
  challanget,
  invoiceget,
  quotationget,
  customerData,
  companyData,
  taxes,
  numberfield,
  currencyData,
  viewQuotionData,
  termsGetin,
}) => {
  const location = useLocation();
  let userId = localStorage.getItem("Id");
  const [uploadExcelfilee, setuPloadExelFi] = useState();
  const userFlagRef = useRef();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [deliveryfilter, setDeliveryFilter] = useState([]);
  const [totalPriceSum, setTotalPriceSum] = useState(0);
  const [description, setDescription] = useState("");
  const [s_no, setSNo] = useState(0);
  const [qty, setQty] = useState(0);
  const [unit_price, setUnitPrice] = useState("");
  const [total_price, setTotalPrice] = useState("");
  const [open, setOpen] = useState(false);
  const [unit, setUnit] = useState("");
  const [pdfType, setPdfType] = useState("");
  const [options, setOptions] = useState([{ tax: "", tax_value: "" }]);
  const [taxValue, settaxValue] = useState([{ taxValue: "" }]);
  const [TermDecccc, SetTermCondiDescri] = useState(
    location.state !== null ? location.state?.terms : ""
  );
  const [totalSum, setTotalSum] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [company_id, setCompanyId] = useState(
    location.state !== null ? location.state?.company_id : ""
  );
  const [customer_id, setCustomerId] = useState(
    location.state !== null ? location.state?.customer_name : ""
  );
  const [termconditonGet, setTermCOndit] = useState();
  const [loading, setLoading] = useState(false);
  const [currency, setcurrency] = useState(
    location.state !== null ? location.state?.currency : ""
  );
  const [nextSNo, setNextSNo] = useState(1);
  const [csvvdataA, setCsvDataa] = useState();
  const [company, setCompany] = useState(
    location.state !== null ? location.state?.customer_name : ""
  );
  const [Modalcompany, setModalCompany] = useState(
    location.state !== null ? location.state?.customer_name : ""
  );
  const [AdditionalPrice, setAdditionalPrice] = useState(
    location.state !== null ? location.state?.customer_name : ""
  );
  const [payment_term, setpayment_term] = useState(
    location.state !== null ? location.state?.payment_term : ""
  );
  const [date, setdate] = useState(
    location.state !== null
      ? location.state?.date
      : new Date().toISOString().split("T")[0]
  );
  const [expDate, setExpDate] = useState(
    location.state !== null ? location.state?.valid_till : ""
  );
  const [quotation_type, setquotation_type] = useState(
    location.state !== null ? location.state?.quotation_type : ""
  );
  const [company_name, setCompanyName] = useState(
    location.state !== null ? location.state?.company_name : ""
  );
  const [number, setNumber_Field] = useState("");

  const calculateTotalSum = (fields) => {
    let sum = 0;
    fields.forEach((field) => {
      sum += parseFloat(field.total_price) || 0;
    });
    return sum.toFixed(2);
  };

  const handleDownloadCsvTemplete = () => {
    window.open(`${downlaodpdfurl}/templetes_csv/quotation.xlsx`);
  };

  useEffect(() => {
    if (termconditonGet) {
      SetTermCondiDescri(termconditonGet);
    }
  }, [termconditonGet]);

  const [fields, setFields] = useState([
    {
      sno: "1",
      description: "",
      qty: 0,
      unit: "",
      unit_price: 0,
      total_price: 0,
    },
  ]);

  const handleImageUpload = async (file, onSuccess, onError) => {
    try {
      if (
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type === "application/vnd.ms-excel"
      ) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data);
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          let excelData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
          if (excelData.length > 0) {
            excelData = excelData.slice(1);
          }
          const arrayOfObjects = excelData.map((row) => {
            const obj = {};
            obj["description"] = row[0]; // Assuming the description is in the first column
            obj["qty"] = row[1]; // Assuming the unit is in the second column
            // obj['unit'] = row[2]; // Assuming the unit price is in the third column
            obj["unit_price"] = row[3]; // Assuming the unit price is in the third column
            obj["total_price"] = row[1] + row[3]; // Assuming the unit price is in the third column
            return obj;
          });

          const updatedFields = arrayOfObjects.map((obj) => obj);
          setFields([...updatedFields]);
          setIsDeleteButtonEnabled(true); // Enable the delete button
          setuPloadExelFi(excelData);
          onSuccess();
        };
        reader.readAsArrayBuffer(file);
      } else {
        throw new Error("Invalid file type. Only Excel files are allowed.");
      }
    } catch (error) {
      onError(error);
    }
  };

  const updateSno = (fields) => {
    return fields.map((field, index) => ({ ...field, sno: index + 1 }));
  };

  const handleFieldChange = (index, field, value) => {
    const updatedFields = [...fields];
    updatedFields[index][field] = value;
    setFields(updatedFields);
  };

  useEffect(() => {
    let index = 0;
    const updatedFields = [...fields];
    const qty = parseFloat(updatedFields[index].qty);
    const unitPrice = parseFloat(updatedFields[index].unit_price);
    updatedFields[index].total_price = (qty * unitPrice).toFixed(2);
    setFields(updatedFields);
  }, []);

  const [fieldData, setFieldData] = useState({
    description: [],
    s_no: [],
    qty: [],
    unit_price: [],
    total_price: [],
    company_id: [company_id],
    customer_id,
    unit,
    number,
    quotation_type,
    payment_term,
    date,
    valid_till: expDate,
    currency,
    tax_id: [],
    is_parent: [1],
  });

  const [taxadd, setTaxAdd] = useState([{ tax_id: "" }]);

  const addField = () => {
    setNextSNo(nextSNo + 1);
    setFields([
      ...fields,
      { sno: nextSNo, description: "", qty: 0, unitPrice: 0, total_price: 0 },
    ]);
  };

  const addTaxxField = () => {
    setTaxAdd([...taxadd, { tax_id: "" }]);
  };
  const createdIddd = localStorage.getItem("newIdd");

  const handleTaxesChange = (index, field, value) => {
    const updateDrop = [...taxadd];
    updateDrop[index][field] = value;
    setTaxAdd(updateDrop);
  };

  const updateFieldData = () => {
    const updatedFieldData = {
      description: [],
      s_no: [],
      qty: [],
      unit_price: [],
      total_price: [],
      company_id: [company_id],
      customer_id,
      number,
      quotation_type,
      payment_term,
      date,
      valid_till: expDate,
      currency,
      tax_id: [],
      is_parent: [1],
    };

    fields.forEach((field) => {
      updatedFieldData.s_no.push(field.sno);
      updatedFieldData.description.push(field.description);
      updatedFieldData.qty.push(field.qty);
      updatedFieldData.unit_price.push(field.unit_price);
      updatedFieldData.total_price.push(field.total_price);
    });
    setFieldData(updatedFieldData);
  };
  const calculateTotalPrice = (fields) => {
    const updatedFields = fields.map((field) => {
      const qty = parseFloat(field.qty);
      const unitPrice = parseFloat(field.unit_price);
      field.total_price = (qty * unitPrice).toFixed(2);
      return field;
    });
    return updatedFields;
  };

  useEffect(() => {
    const updatedFields = calculateTotalPrice(fields);
    const newTotalSum = calculateTotalSum(fields);
    setSubTotal(newTotalSum);
  }, [fields]);

  useEffect(() => {
    const taxVal = options.map((option) => option.tax_value).filter(Boolean);
    const taxType = options.map((option) => option.tax_type).filter(Boolean);
    setTotalSum(Number(subTotal));
    let totalSumWithTax = Number(subTotal);

    for (let i = 0; i < taxType.length; i++) {
      const adjustedValue = parseFloat(taxVal[i]);
      if (taxType[i] === "1") {
        const taxAmount = Number(subTotal) * (Number(adjustedValue) / 100);
        totalSumWithTax += Number(taxAmount);
      } else {
        totalSumWithTax += adjustedValue;
      }
    }
    setTotalSum(totalSumWithTax);
  }, [fields, options, subTotal]);

  const handleCompanyChange = (key, value) => {
    const updatedData = data.map((item) =>
      item.key === key ? { ...item, company: value } : item
    );
    setData(updatedData);
    console.log(updatedData, "Ssdsd");
    const newRow = updatedData.find((item) => item.key === key);
    if (newRow) {
      setModalCompany(newRow.company);
    }
  };

  const handleAdditionalPriceChange = (key, value) => {
    const updatedData = data.map((item) =>
      item.key === key ? { ...item, additionalPrice: value } : item
    );
    setData(updatedData);
    const newRow = updatedData.find((item) => item.key === key);
    if (newRow) {
      setAdditionalPrice(newRow.additionalPrice);
    }
  };

  const handleDeleteRowTable = (index) => {
    if (fields.length > 1) {
      const updatedFields = [...fields];
      updatedFields.splice(index, 1); // Remove the row at the given index
      setFields(updateSno(updatedFields)); // Recalculate sno for all rows
    }
  };

  const initialData = [{ key: 0, company: "", additionalPrice: "" }];
  const [data, setData] = useState(initialData);
  const [isDeleteButtonEnabled, setIsDeleteButtonEnabled] = useState(
    data.length > 1
  );

  const options2 =
    customerData && customerData.length > 0
      ? customerData.map((v) => ({
          value: v.company_name,
          label: v.company_name,
        }))
      : [];

  const TermCoditoptions =
    quotationsssactive && quotationsssactive?.data?.length > 0
      ? quotationsssactive?.data?.map((v) => ({
          value: v.term_description,
          label: v.term_name,
        }))
      : [];

  const currencyOption =
    currencyData && currencyData.length > 0
      ? currencyData.map((v) => ({ value: v.cur_name, label: v.cur_name }))
      : [];

  const handleChangee = (value) => {
    setCustomerId(value);
  };

  const HandleTermCondiotonHadle = (value) => {
    setTermCOndit(value);
  };
  const handleChangeee = async (value) => {
    setCompanyId(value);
    await dispatch(fetchNumberField({ id: value }));

    setNumber_Field(numberfield?.data);
  };

  useEffect(() => {
    handleChangeee(localStorage.getItem("companyName"));
  }, []);

  const handleAddRow = () => {
    const updatedFields = [
      ...fields,
      { description: "", qty: 0, unit_price: 0, total_price: 0 },
    ];
    setFields(updateSno(updatedFields));

    setIsDeleteButtonEnabled(true); // Enable the delete button
  };

  const handleDeleteRow = (key) => {
    const updatedData = data.filter((item) => item.key !== key);
    setData(updatedData);
    if (updatedData.length === 1) {
      setIsDeleteButtonEnabled(false); // Disable the delete button
    }
  };

  const columns = [
    {
      title: "S.no",
      dataIndex: "sno",
      width: 100,
      key: "sno",
      fixed: "left",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Description*",
      dataIndex: "description",
      key: "description",
      fixed: "left",
      render: (text, record, index) => (
        <Input
          value={record.description}
          onChange={(e) =>
            handleFieldChange(index, "description", e.target.value)
          }
        />
      ),
    },

    {
      title: "Quantity*",
      dataIndex: "qty",
      key: "qty",
      render: (text, record, index) => (
        <Input
          type="number"
          value={record?.qty}
          onChange={(e) => {
            if (record.unit === "Pcs") {
              handleFieldChange(
                index,
                "qty",
                Math.max(
                  0,
                  parseInt(Math.max(0, parseFloat(e.target.value)), 10)
                )
              );
            } else {
              handleFieldChange(
                index,
                "qty",
                Math.max(0, parseFloat(e.target.value))
              );
            }
          }}
        />
      ),
    },
    {
      title: "Unit Price*",
      dataIndex: "unit_price",
      key: "unit_price",
      render: (text, record, index) => (
        <Input
          type="number"
          value={record?.unit_price === NaN ? 0 : record?.unit_price}
          onChange={(e) =>
            handleFieldChange(
              index,
              "unit_price",
              Math.max(0, parseFloat(e.target.value)) == NaN
                ? 0
                : Math.max(0, parseFloat(e.target.value))
            )
          }
        />
      ),
    },

    {
      dataIndex: "total_price",
      key: "total_price",
      render: (text, record, index) => (
        <Input
          type="number"
          value={isNaN(record?.total_price) ? 0 : record?.total_price}
          disabled={true}
          onChange={(e) =>
            handleFieldChange(index, "total_price", e.target.value)
          }
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record, index) => (
        <Space>
          <Button
            style={{ backgroundColor: "lightblue" }}
            onClick={() => handleAddRow()}
          >
            <PlusOutlined size={25} color={"#fff"} />
          </Button>
          <Button
            style={{ backgroundColor: "red" }}
            onClick={() => handleDeleteRowTable(index)}
            disabled={!isDeleteButtonEnabled} // Disable the button if isDeleteButtonEnabled is false
          >
            <DeleteOutlined size={25} color={"#fff"} />
          </Button>
        </Space>
      ),
    },
  ];

  const handleAddRowModal = () => {
    const newRow = {
      key: data.length,
      company: "", // Initialize with Modalcompany
      additionalPrice: "", // Initialize with AdditionalPrice
    };
    setData([...data, newRow]);
    setIsDeleteButtonEnabled(true); // Enable the delete button
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    dispatch(fetchCustomer());
    dispatch(fetchCompanyDataa());
    dispatch(fetchAllVwwTrmsoitins());
    dispatch(fetchQuoatationGetAll());
    dispatch(fetchTaxesComp());
    dispatch(fetchCurrency());
  }, []);

  useEffect(() => {
    setLoading(true);
    dispatch(fetchAllTrmActive({ setLoading: setLoading }));
  }, []);

  useEffect(() => {
    if (company_id) {
      setNumber_Field(numberfield?.data);
    }
  }, [company_id, numberfield]);

  useEffect(() => {
    if (location.state) {
      const payload = { id: location.state?.id };
      dispatch(
        fetchViewQuotation({
          payload,
          setLoading: setLoading,
        })
      );
      handleChangeee(location.state?.company_id);

      console.log("editData", location.state);
    }
  }, [location]);

  useEffect(() => {
    if (location.state && viewQuotionData) {
      console.log("editData2", viewQuotionData);
      if (
        viewQuotionData?.main_quotation?.taxes &&
        viewQuotionData?.main_quotation?.taxes.length
      ) {
        let taxItemsData = viewQuotionData?.main_quotation?.taxes.map((v) => {
          return { tax: v.id, ...v };
        });
        setOptions(taxItemsData);
      } else {
        setOptions([{ tax: "", tax_value: "" }]);
      }
      if (
        viewQuotionData?.main_quotation &&
        viewQuotionData?.main_quotation?.qt_items &&
        viewQuotionData?.main_quotation?.qt_items.length
      ) {
        let qtItemsData = viewQuotionData?.main_quotation?.qt_items.map((v) => {
          return {
            sno: v.s_no,
            description: v.description,
            qty: Number(v.qty),
            unit: v.unit_type,
            unit_price: Number(v.unit_price),
            total_price: Number(v.total_price),
          };
        });
        setFields(qtItemsData);
      }
    }
  }, [viewQuotionData]);

  const handleSubmit = () => {
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const htmlString = html.toString();
    const payload = {
      // pdf_type: pdfType,
      description: fields
        .map((field) => field.description)
        .filter((val) => val),
      s_no: fields.map((field, i) => i + 1),
      qty: fields.map((field) => field.qty).filter((val) => val),
      unit_type: fields.map((field) => field.unit).filter((val) => val),
      unit_price: fields.map((field) => field.unit_price).filter((val) => val),
      total_price: fields
        .map((field) => field.total_price)
        .filter((val) => val),
      company_id: [
        localStorage.getItem("companyName"),
        ...data.map((field) => field.company).filter((val) => val),
      ],
      customer_id: customer_id,
      valid_till: expDate,
      number,
      quotation_type,
      terms_conditions: TermDecccc,
      payment_term,
      date,
      currency,
      tax_id: options.map((option) => option.tax),
      is_parent: [
        0,
        ...data.map((field) => (field.company ? "1" : "")).filter(Boolean),
      ],
      additional_price: [
        "0",
        ...data.map((field) => field.additionalPrice).filter(Boolean),
      ],
    };
    const {
      pdf_type,
      description,
      s_no,
      qty,
      unit_type,
      unit_price,
      terms_conditions,
    } = payload;

    if (
      description.length &&
      s_no.length &&
      qty.length &&
      // unit_type.length &&
      unit_price.length &&
      localStorage.getItem("companyName") &&
      customer_id.trim() &&
      quotation_type &&
      payment_term.trim() &&
      date &&
      currency &&
      expDate
    ) {
      if (!location.state) {
        dispatch(
          fetchStoreQuotation({
            payload,
            setLoading: setLoading,
          })
        );
        setLoading(true);
      } else {
        dispatch(
          updateStoreQuotation({
            payload: { ...payload, qt_id: location.state.id },
            setLoading: setLoading,
          })
        );
        setLoading(true);
      }
    } else {
      toast.error("Required Fields are empty", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        bodyClassName: "toastStyle",
      });
    }
  };
  let sub_total_value = fields.reduce((acc, val) => {
    return val.total_price === "NaN" ? 0 : acc + Number(val.total_price);
  }, 0);

  const HandleCustomerPlusIcon = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    console.log("chyekcingghghgh", location?.state);
  }, [location?.state]);
  return (
    <MainCard>
      <Spin spinning={loading ? true : companyData ? false : true}>
        <Grid container spacing={2} sx={{ marginTop: "10px" }}>
          <Grid item xs={12}>
            <div className="row">
              {location.state && (
                <div className="col-md-4 my-2">
                  <Stack spacing={1}>
                    <InputLabel
                      htmlFor="AssignRole"
                      style={{ fontSize: "13px" }}
                    >
                      Qoutation Number
                    </InputLabel>
                  </Stack>

                  <ThemeInput
                    style={{
                      width: "100%",
                      height: "54px",
                    }}
                    value={location.state?.quote_num}
                  />
                </div>
              )}
              <div className="col-md-4 my-2">
                <Stack spacing={1}>
                  <InputLabel htmlFor="AssignRole" style={{ fontSize: "13px" }}>
                    Customer*
                  </InputLabel>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <SearchTextSelect
                    handleChangeValue={handleChangee}
                    options={options2}
                    value={customer_id}
                  />
                  <AddIcon
                    style={{ cursor: "pointer" }}
                    onClick={HandleCustomerPlusIcon}
                  />
                </Stack>
              </div>

              <div className="col-md-4 my-2">
                <Stack spacing={1}>
                  <InputLabel htmlFor="AssignRole" style={{ fontSize: "13px" }}>
                    Quotation Type*
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      displayEmpty
                      input={<OutlinedInput />}
                      style={{ height: "52px" }}
                      value={quotation_type}
                      onChange={(e) => setquotation_type(e.target.value)}
                    >
                      {/* You can add your select options here */}
                      <MenuItem disabled value="">
                        <em>Select Quotation Type</em>
                      </MenuItem>

                      <MenuItem value="1">Recieving</MenuItem>
                      <MenuItem value="2">ADHOC</MenuItem>
                      <MenuItem value="3">Quotation Vice</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              </div>

              <div className="col-md-4 my-2">
                <ThemeInput
                  type="date"
                  name="Date*"
                  value={date}
                  onChange={(e) => setdate(e.target.value)}
                />
              </div>

              <div className="col-md-4 my-2">
                <ThemeInput
                  type="text"
                  name="Quotation Serial Number*"
                  value={number}
                  disabled={true}
                  onChange={(e) => setNumber_Field(e.target.value)}
                />
              </div>

              <div className="col-md-4 my-2">
                <ThemeInput
                  name="Payment Term*"
                  value={payment_term}
                  placeholder="Payment Term"
                  onChange={(e) => setpayment_term(e.target.value)}
                />
              </div>

              <div className="col-md-4 my-2">
                <Stack spacing={1}>
                  <InputLabel htmlFor="AssignRole" style={{ fontSize: "13px" }}>
                    Currency*
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth></FormControl>
                </Stack>

                <SelectAnt
                  showSearch
                  style={{
                    width: "100%",
                    height: "50px",
                  }}
                  onChange={(value) => setcurrency(value)}
                  tokenSeparators={[","]}
                  options={currencyOption}
                  value={currency}
                  placeholder="Select Currency"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                />
              </div>
              <div className="col-md-6 my-2">
                <ThemeInput
                  type="date"
                  name="Expiry Date*"
                  value={expDate}
                  onChange={(e) => setExpDate(e.target.value)}
                />
              </div>
              <div className="col-md-6 my-2">
                <Stack spacing={1}>
                  <InputLabel htmlFor="AssignRole" style={{ fontSize: "13px" }}>
                    Term Conditons Select*
                  </InputLabel>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <SearchTextSelect
                    handleChangeValue={HandleTermCondiotonHadle}
                    options={TermCoditoptions}
                    value={termconditonGet}
                  />
                </Stack>
              </div>
              <div className="col-md-12 my-2">
                <label className="mr-3">"Terms Conditions"</label>
                <div style={{ height: "300px", overflowY: "scroll" }}>
                  <ReactQuill
                    theme="snow"
                    value={TermDecccc}
                    onChange={SetTermCondiDescri}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 my-2">
                <div className="row">
                  <div className="col-sm-5">
                    <p
                      style={{
                        marginLeft: "2px",
                        color: "black",
                        textAlign: "left",
                        marginBottom: "6px",
                      }}
                      className="colorgray"
                    >
                      Import CSV
                    </p>

                    <Upload
                      customRequest={({ file, onSuccess, onError }) =>
                        handleImageUpload(file, onSuccess, onError)
                      }
                      id={`image-option`}
                      listType="picture"
                      maxCount={1}
                      accept=".xlsx, .xls"
                      value={uploadExcelfilee}
                      showUploadList={true}
                    >
                      <Button
                        fullWidth
                        className="flex items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none my-1"
                      >
                        <UploadOutlined style={{ marginRight: 10 }} /> Upload
                        (Max: 1)
                      </Button>
                    </Upload>
                  </div>
                  <div className="col-sm-5">
                    <p
                      style={{
                        color: "black",
                        textAlign: "left",
                        marginBottom: "6px",
                      }}
                      className="colorgray"
                    >
                      Download CSV
                    </p>
                    <Button
                      className=" rounded-xl border bg-white/0 p-3 text-sm outline-none my-1"
                      onClick={handleDownloadCsvTemplete}
                      startIcon={<CloudDownloadIcon />}
                    >
                      CSV Template
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <Table
                className="rounded"
                pagination={false}
                columns={columns}
                dataSource={fields}
                scroll={{
                  x: 1300,
                }}
              />
            </div>
            <div className="row d-flex justify-content-between mt-3">
              <div className="col-md-6 my-2">
                <ThemeInput
                  name="Sub Total*"
                  disabled={true}
                  value={sub_total_value}
                  placeholder="Total"
                  // onChange={(e) => setpayment_term( e.target.value )}
                />
              </div>
            </div>
            <div className="row">
              <TaxOption
                options={options}
                setOptions={setOptions}
                taxValue={taxValue}
                settaxValue={settaxValue}
                sub_total_value={sub_total_value}
              />
            </div>

            <div className="row justify-content-end">
              <div className="col-md-6 my-2">
                <ThemeInput
                  name="Total*"
                  disabled={true}
                  value={totalSum}
                  placeholder="Total"
                  // onChange={(e) => setpayment_term( e.target.value )}
                />
              </div>
            </div>

            <div className="row my-6">
              <div className=" my-2 text-end">
                <div style={{ display: "flex", justifyContent: "right" }}>
                  {!location.state ? (
                    <div>
                      <Button
                        onClick={showModal}
                        disableElevation
                        className="px-3"
                        size="large"
                        type="submit"
                        variant="contained"
                        style={{ backgroundColor: "lightblue", color: "black" }}
                      >
                        Add Multiple Companies
                        <PlusOutlined
                          size={25}
                          color={"#fff"}
                          style={{ marginLeft: "10px", color: "#38454A" }}
                        />
                      </Button>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <Button
                    onClick={handleSubmit}
                    disableElevation
                    className="px-3"
                    sx={{ marginLeft: "20px" }}
                    size="large"
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    {location.state === null
                      ? "Save Quotation"
                      : "Update Quotation"}
                  </Button>
                </div>

                <Modal
                  title="Add Multiple Companies"
                  open={isModalOpen}
                  onOk={handleOk}
                  onCancel={handleCancel}
                >
                  <Button type="primary" onClick={handleAddRowModal}>
                    <PlusOutlined /> Add Row
                  </Button>
                  <Table
                    pagination={false}
                    columns={[
                      {
                        title: "Comapany",
                        dataIndex: "company",
                        key: "company",
                        render: (text, record) => (
                          <Stack spacing={0}>
                            <FormControl variant="outlined" fullWidth>
                              <Select
                                displayEmpty
                                input={<OutlinedInput />}
                                style={{ height: "52px" }}
                                value={record?.company}
                                onChange={(e) =>
                                  handleCompanyChange(
                                    record.key,
                                    e.target.value
                                  )
                                }
                              >
                                {/* You can add your select options here */}
                                <MenuItem value="">
                                  <em>Select Company</em>
                                </MenuItem>
                                {companyData &&
                                  companyData?.length > 0 &&
                                  companyData?.map((v, e) => {
                                    return (
                                      <MenuItem value={v.id}>
                                        {v.company_name}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </FormControl>
                          </Stack>
                        ),
                      },
                      {
                        title: "Additional Price",
                        dataIndex: "Additional Price",
                        key: "Additional Price",
                        render: (text, record) => (
                          <ThemeInput
                            type="number"
                            value={record.additionalPrice}
                            onChange={(e) =>
                              handleAdditionalPriceChange(
                                record.key,
                                e.target.value
                              )
                            }
                          />
                        ),
                      },
                      {
                        title: "Action",
                        key: "action",
                        render: (text, record) => (
                          <Space>
                            <Button
                              onClick={() => handleDeleteRow(record.key)}
                              danger
                              disabled={data.length === 1}
                            >
                              <DeleteOutlined /> Delete
                            </Button>
                          </Space>
                        ),
                      },
                    ]}
                    dataSource={data}
                  />
                </Modal>
              </div>
            </div>
          </Grid>
        </Grid>
      </Spin>
      <AddCompanyModal open={open} handleClose={handleClose} />
    </MainCard>
  );
};

const mapStateToProps = (state) => {
  return {
    challanget: state?.getchallan.challanget,
    companyData: state?.company?.companyData?.data,
    customerData: state?.customerdata?.customerData?.data,
    currencyData: state?.currency?.currencyData?.data,
    invoiceget: state.getinvoiceall.invoiceget,
    quotationget: state.quotationall.quotationget,
    termsGetin: state.termsAlling.termsGetin,
    taxes: state?.taxesdata?.taxes?.data,
    numberfield: state?.customernumberfield?.numberfield,
    viewQuotionData: state?.quotationView?.viewQuotation?.data,
    quotationsssactive: state?.quotationssActiveDetail?.quotationsssactive,
  };
};
export default connect(mapStateToProps)(CreateQuotation);
